<template>
  <div id="modal" class="modal" ref="ticketForm">
    <div class="modal-content">
      <div v-if="ticket">
        <h5>Тикет - {{ ticket.id }}</h5>
        <div class="table_wrapper">
          <table class="highlight">
            <thead>
              <tr>
                <th>Оператор</th>
                <th>Комментарий</th>
                <th>Дата</th>
                <th>Статус</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{ `#${ticket.operator}${ticket.auth_user.username}` }}
                </td>
                <td>{{ ticket.comment }}</td>
                <td>
                  {{
                    ticket.create_ticket
                      ? transformDate(ticket.create_ticket)
                      : ''
                  }}
                </td>
                <td>{{ statuses[ticket.status] }}</td>
              </tr>
              <tr
                v-for="ticketHistory of ticket.cabinet_updatetickets"
                :key="ticketHistory.id"
              >
                <td>
                  {{ `#${ticketHistory.operator}${ticketHistory.username}` }}
                </td>
                <td>{{ ticketHistory.comment }}</td>
                <td>
                  {{ transformDate(ticketHistory.created_at) }}
                </td>
                <td>{{ statuses[ticketHistory.status] }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <button
        v-if="ticket && ticket.files && !downloadFile"
        class="btn btn-download-file"
        @click="getTicketFile(ticket.files)"
      >
        Получить файл
      </button>
      <div v-if="downloadFile" class="wrapper-file-result">
        <img :src="downloadFile" height="200px" />
        <a :href="downloadFile" download>Скачать файл</a>
      </div>

      <form
        v-if="!ticket || ticket.status !== 1"
        @submit.prevent="updateTicket"
      >
        <h5>{{ (!ticket ? 'Создать' : 'Обновить') + ' тикет:' }}</h5>
        <div class="input-field ">
          <textarea
            id="textarea"
            class="materialize-textarea"
            rows="4"
            v-model="comment"
          ></textarea>
          <label for="textarea">Комментарий</label>
        </div>
        <div v-if="!ticket && fileForm" class="file-field input-field">
          <div class="btn">
            <span><i class="material-icons">file_download</i></span>
            <input type="file" @change="onFileChange" size="5242880" />
          </div>
          <div class="file-path-wrapper">
            <input
              class="file-path validate"
              type="text"
              placeholder="Приложить данные"
            />
          </div>
        </div>
        <div class="row">
          <div class="input-field">
            <select id="status" v-model="status" ref="selectForm">
              <option value="" disabled selected>Выберите новый статус</option>
              <option
                v-for="(status, key) of statuses"
                :key="key"
                :value="key"
                >{{ status }}</option
              >
            </select>
            <label for="status">Статус</label>
          </div>
        </div>
        <button class="waves-effect waves-light btn btn_submit">
          <i class="material-icons right">send</i
          >{{ (!ticket ? 'Создать' : 'Обновить') + ' тикет' }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import API from '../../../utils/API';
import { mapTicketStatus } from '../../../utils/mapTicketStatus';
import { getFullFormatDate } from '../../../utils/transformDate';

export default {
  name: 'ticket-form',
  props: {
    ticket: Object,
    id: Number,
    target: String
  },
  data() {
    return {
      statuses: mapTicketStatus,
      comment: '',
      status: '0',
      file: '',
      fileForm: true,
      downloadFile: '',
      ticketForm: null,
      selectForm: null
    };
  },
  methods: {
    onFileChange(e) {
      this.file = '';
      if (e.target.files[0] && e.target.files[0].size <= 5242880) {
        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = () => {
          const readerResult = reader.result;
          const regex = /image\/(.{0,6});/;
          const type = readerResult.match(regex)[1];
          const file = readerResult.split(',')[1];
          this.file = [file, type];
        };
      } else {
        /* global M */
        M.toast({
          html: `[Ошибка]: Ваш файл занимает ${(
            e.target.files[0].size / 1048576
          ).toFixed(2)}МБ из 5МБ`,
          classes: 'red darken-3',
          displayLength: 10000
        });
        this.fileForm = false;
        this.$nextTick(() => {
          this.fileForm = true;
        });
      }
    },
    async getTicketFile(file_name) {
      this.$store.commit('toggleLoad', true);
      const kabinetAPI = new API();
      const file = await kabinetAPI.getTicketFile(file_name);
      this.downloadFile = file;
    },
    async updateTicket() {
      this.$store.commit('toggleLoad', true);
      const kabinetAPI = new API();
      if (!this.status) {
        M.toast({
          html: `[Ошибка]: Выберите статус`,
          classes: 'red darken-3',
          displayLength: 5000
        });

        this.$store.commit('toggleLoad', false);
        return;
      }

      if (!this.ticket) {
        await kabinetAPI.createTicket({
          id: this.id,
          target: this.target,
          status: this.status,
          comment: this.comment,
          file: this.file
        });
      } else {
        await kabinetAPI.updateTicket({
          ticket_id: this.ticket.id,
          status: this.status,
          comment: this.comment
        });
      }

      this.$emit('refresh-tickets');
      this.$emit('destroy-form');
      this.$store.commit('toggleLoad', false);
    },
    transformDate(date) {
      return getFullFormatDate(date);
    }
  },
  mounted() {
    this.selectForm = M.FormSelect.init(this.$refs.selectForm, {});
    this.ticketForm = M.Modal.init(this.$refs.ticketForm, {
      onCloseEnd: () => {
        this.$emit('destroy-form');
      }
    });
    this.ticketForm.open();
  },
  beforeDestroy() {
    if (this.selectForm && this.selectForm.destroy) {
      this.selectForm.destroy();
    }
    if (this.ticketForm && this.ticketForm.destroy) {
      this.ticketForm.destroy();
    }
  }
};
</script>

<style lang="scss" scoped>
.file-field {
  .btn {
    background-color: #1e9277;
  }
}
.btn-download-file {
  margin-top: 20px;
  width: 100%;
}

.wrapper-file-result {
  position: relative;
  a {
    border: 1px solid black;
    background-color: white;
    padding: 0 3px;
    color: black;
    position: absolute;
    left: 0;
    top: 15px;
  }
}
</style>
