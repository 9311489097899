<template>
  <div class="base_wrapper">
    <h5>Результат поиска</h5>
    <SearchFilter
      @filter-request="filterBases"
      @clear-filter="clearFilterBase"
      @sort-by-date="sortByDate"
      @close-collapse="closeCollapse"
    />
    <div v-if="tickets.length" class="result_table_wrapper">
      <div class="wrapper_grid header_grid">
        <div>ID тикета</div>
        <div>ID анкеты</div>
        <div>Оператор</div>
        <div>Статус</div>
        <div>База</div>
        <div>Дата</div>
      </div>
      <ul
        class="collapsible"
        ref="collapse"
        :class="{ 'disabled-table': disabledTable }"
      >
        <li v-for="ticket of items" :key="ticket.id">
          <div
            class="collapsible-header wrapper_grid item_grid"
            @click="setActiveClass(ticket)"
          >
            <div>{{ ticket.id }}</div>
            <div>
              {{ ticket.questionnaire_id }}
            </div>
            <div>
              {{ `# ${ticket.operator} ${ticket.auth_user.username}` }}
            </div>
            <div>{{ statuses[ticket.status] }}</div>
            <div>{{ ticket.target }}</div>
            <div>{{ getformateDate(ticket.create_ticket) }}</div>
          </div>
          <div class="collapsible-body shadow_body" :class="ticket.target">
            <transition name="ticket">
              <TicketZero
                v-if="ticket.isShow && ticket.questionnaire_id == 0"
                :user="ticketInfo"
                :target="ticket.target"
                :key="`${ticket.id + 'zero'}`"
              />

              <Ticket
                v-if="ticket.isShow && ticket.questionnaire_id != 0"
                :user="ticketInfo"
                :target="ticket.target"
                :key="`${ticket.id + `defualt`}`"
              />
            </transition>
          </div>
        </li>
      </ul>
    </div>
    <Pagination
      v-if="pageCount > 1"
      :pageCount="pageCount"
      @click="changePageHandler"
      :page="page"
    />
  </div>
</template>

<script>
import SearchFilter from '../components/app/SearchBar/SearchFilter';
import Ticket from '../views/Ticket';
import TicketZero from '../views/TicketZero';
import Pagination from '../components/app/Pagination';
import API from '../utils/API';
import { deepClone } from '../utils/deepClone.js';
import { mapTicketStatus } from '../utils/mapTicketStatus';
import { getYMDFormatDate } from '../utils/transformDate';
import { pageCount, sliceData as allItems } from '../utils/pagination';

export default {
  name: 'search-tickets-table',
  components: {
    Ticket,
    SearchFilter,
    Pagination,
    TicketZero
  },
  data() {
    return {
      page: 1,
      showItemsCount: 20,
      filtredTickets: [],
      tickets: [],
      statuses: mapTicketStatus,
      isCloseCollapse: false,
      disabledTable: false,
      collapse: null,
      ticketInfo: null,
      currentUser: null
    };
  },
  computed: {
    pageCount() {
      return pageCount(this.filtredTickets, this.showItemsCount);
    },
    sliceData() {
      return allItems(this.filtredTickets, this.pageCount, this.showItemsCount);
    },
    items() {
      if (this.page === 0) {
        return this.sliceData[0];
      }
      return this.sliceData[this.page - 1];
    }
  },
  watch: {
    pageCount(value) {
      this.page = this.page > value ? value : this.page;
    },
    isCloseCollapse(value) {
      if (value) {
        let activeItemIndex = null;
        this.collapse.el.children.forEach((listItem, index) => {
          if (listItem.classList.contains('active')) {
            activeItemIndex = index;
          }
        });

        this.collapse.close(activeItemIndex);
        this.disabledTable = false;
      }
    }
  },
  methods: {
    async setActiveClass(ticket) {
      if (this.currentUser !== ticket.id) {
        if (ticket.questionnaire_id === 0) {
          this.ticketInfo = ticket;
        } else {
          const kabinetAPI = new API();
          const response = await kabinetAPI.getQuestionary({
            id: ticket.questionnaire_id,
            base: [ticket.target]
          });
          this.ticketInfo = response.result.message[ticket.target].users[0];
        }
        this.filtredTickets.map(i => {
          if (i.id === this.currentUser) {
            i.isShow = false;
          }
        });
        this.disabledTable = true;
        ticket.isShow = true;
        this.currentUser = ticket.id;
        return;
      }
      this.disabledTable = !this.disabledTable;
      ticket.isShow = !ticket.isShow;
    },
    changePageHandler(page) {
      this.disabledTable = false;
      this.page = page;
    },
    getformateDate(date) {
      return getYMDFormatDate(date);
    },
    filterBases(filterValue) {
      const filtredResult = this.filtredTickets.filter(ticketData => {
        return (
          ticketData.questionnaire_id.toString().includes(filterValue) ||
          ticketData.id.toString().includes(filterValue) ||
          this.statuses[ticketData.status]
            .toUpperCase()
            .includes(filterValue.toUpperCase()) ||
          ticketData.target
            .toString()
            .toUpperCase()
            .includes(filterValue.toUpperCase())
        );
      });
      this.filtredTickets = filtredResult;
    },
    sortByDate() {
      this.filtredTickets.sort((a, b) => {
        return (
          new Date(b.create_ticket).valueOf() -
          new Date(a.create_ticket).valueOf()
        );
      });
    },
    clearFilterBase() {
      this.filtredTickets = deepClone(this.tickets);
    },
    closeCollapse() {
      this.isCloseCollapse = true;
      this.$nextTick(() => {
        this.isCloseCollapse = false;
      });
    }
  },
  created() {
    this.tickets = this.$store.state.tickets.tickets;
    this.filtredTickets = deepClone(this.tickets);
  },
  mounted() {
    /* global M */
    this.collapse = M.Collapsible.init(this.$refs.collapse, {});
  },
  beforeDestroy() {
    if (this.collapse && this.collapse.destroy) {
      this.collapse.destroy();
    }
  }
};
</script>

<style lang="scss" scoped>
@media (max-width: 600px) {
  .base_wrapper {
    overflow-x: auto;
    .result_table_wrapper {
      min-width: 600px;
    }
  }
}

.disabled-table {
  li:not(.active) {
    opacity: 0.6;
  }
}

.collapsible {
  margin-top: 0;
  min-width: 500px;
  border: 0;
  box-shadow: 0 0 0 0;
}
.collapsible-header {
  border: 0;
  padding: 0;
}

.collapsible-body {
  padding: 0;
  border-bottom: none;
}

.shadow_body {
  margin: 10px 0;
  padding: 5px;
  box-shadow: 0 0 10px 5px #4cafa640;
  &.alfagrad {
    box-shadow: 0 0 10px 5px #d048482e;
  }
  &.sbergrad {
    box-shadow: 0 0 10px 5px #1a841e24;
  }
  &.ukragrad {
    box-shadow: 0 0 10px 5px #bdbf3647;
  }
  &.dadimcash {
    box-shadow: 0 0 10px 5px #266cd62e;
  }
  &.delta {
    box-shadow: 0 0 10px 5px #279c9c2e;
  }
}

.wrapper_grid {
  display: grid;
  grid-template-columns: 60px 60px 1fr 90px 110px 75px;
  font-size: 13px;
  &.header_grid {
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      background: #eee;
      font-weight: 500;
    }
  }
  &.item_grid {
    &:hover:not(.activeStatus):not(.errorStatus) {
      background: #005aff12;
    }
    &.activeStatus,
    &.errorStatus {
      &:hover {
        backdrop-filter: brightness(95%);
      }
    }
    div {
      overflow: hidden;
      padding: 2px;
    }
  }
  div {
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
}
.activeStatus {
  background: #0080003b;
}

.errorStatus {
  background: #ff00004d;
}

.ticket-leave-active {
  transition-duration: 0.5s;
}
.ticket-enter-active {
  transition-duration: 1s;
}
</style>
