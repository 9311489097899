<template>
  <form @submit.prevent="submitHandler" class="form_grid">
    <div class="input-field">
      <input
        id="search"
        type="text"
        placeholder="Телефон, фамилия, номер карты"
        required
        v-model.trim="searchRequest"
        autocomplete="off"
      />
      <label for="search">Ключи</label>
    </div>
    <button
      type="submit"
      class="waves-effect waves-light btn-floating btn_filter"
    >
      <i class="material-icons">find_in_page</i>
    </button>
    <button
      type="button"
      class="waves-effect waves-light btn-floating btn_new"
      @click="sortByDate"
    >
      NEW
    </button>
    <button
      type="button"
      class="waves-effect waves-light btn-floating btn_clear"
      @click="clearRequestHandler"
    >
      <i class="material-icons">clear</i>
    </button>
  </form>
</template>

<script>
export default {
  name: 'search-filter',
  data() {
    return {
      searchRequest: ''
    };
  },
  methods: {
    submitHandler() {
      this.$emit('close-collapse');
      this.$emit('filter-request', this.searchRequest);
    },
    sortByDate() {
      this.$emit('close-collapse');
      this.$emit('sort-by-date');
    },
    clearRequestHandler() {
      this.searchRequest = '';
      this.$emit('close-collapse');
      this.$emit('clear-filter');
    }
  },
  mounted() {
    /* global M */
    M.updateTextFields();
  }
};
</script>

<style lang="scss" scoped>
.form_grid {
  display: flex;
  align-content: center;
  .input-field {
    flex-grow: 1;
  }
  button {
    margin-left: 5px;
    margin-top: 1em;
    min-width: 40px;
    &.btn_filter {
      background-color: #4caf50;
    }
    &.btn_new {
      background-color: #26a69a;
    }
    &.btn_clear {
      background-color: #d8d2c6;
    }
  }
}
</style>
