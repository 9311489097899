<template>
  <paginate-core
    :value="page"
    :page-count="pageCount"
    :click-handler="changePageHandler"
    :prev-text="'<'"
    :next-text="'>'"
    :container-class="'pagination'"
    :page-class="'waves-effect'"
    :active-class="`active ${title}`"
  />
</template>

<script>
export default {
  name: 'pagination',
  props: ['pageCount', 'page', 'title'],
  methods: {
    changePageHandler(page) {
      this.$emit('click', page);
    }
  }
};
</script>

<style lang="scss">
.pagination {
  .active {
    &.alfa {
      background-color: #d04848;
    }
    &.sber {
      background-color: #1a841e;
    }
    &.ukr {
      background-color: #bdbf36;
    }
    &.dadim {
      background-color: #266cd6;
    }
    &.delta {
      background-color: #279c9c;
    }
    a {
      color: white;
    }
  }
}
</style>
