<template>
  <div class="base_wrapper" v-if="items !== undefined">
    <h5>{{ getCapitalLetter(title) }}</h5>
    <div class="result_table_wrapper">
      <div class="wrapper_grid header_grid" :class="getPrefix(title)">
        <div>ID</div>
        <div>ФИО</div>
        <div>Телефон</div>
        <div>Email</div>
        <div>Pan</div>
        <div>Дата вереф.</div>
      </div>
      <ul
        class="collapsible"
        ref="collapse"
        :class="{ 'disabled-table': disabledTable }"
      >
        <li v-for="user of items" :key="user.id">
          <div
            class="collapsible-header wrapper_grid item_grid"
            :class="{
              activeStatus:
                Boolean(user.pan) && user.subscription_id.status === 'active',
              errorStatus:
                Boolean(user.pan) && user.subscription_id.status !== 'active'
            }"
            @click="setActiveClass(user.id)"
          >
            <div>{{ user.id }}</div>
            <div>
              {{
                `${user.contact.lastname} ${user.contact.firstname} ${user.contact.patronymic}`
              }}
            </div>
            <div>{{ user.contact.phone }}</div>
            <div>{{ user.contact.email }}</div>
            <div>{{ user.pan }}</div>
            <div>{{ getVerificateDate(user.payment_transaction) }}</div>
          </div>
          <button class="copy-button" :class="{ 'successed-copied-btn': copiedBtn }" @click="copyFirstFields(user.id, `${user.contact.lastname} ${user.contact.firstname} ${user.contact.patronymic}` ,user.contact.phone)"> 
            <span>{{ copyBtnLabel }}</span>
          </button>
          <div class="collapsible-body shadow_body" :class="getPrefix(title)">
            <transition name="ticket">
              <Ticket
                v-if="user.isShow"
                :user="user"
                :target="title"
                :key="user.id"
              />
            </transition>
          </div>
        </li>
      </ul>
    </div>
    <Pagination
      v-if="pageCount > 1"
      :pageCount="pageCount"
      @click="changePageHandler"
      :page="page"
      :title="getPrefix(title)"
    />
  </div>
</template>

<script>
import Ticket from '../../../views/Ticket';
import Pagination from '../Pagination';
import { pageCount, sliceData as allItems } from '../../../utils/pagination';
import {
  getYMDFormatDate,
  getCapitalLetter
} from '../../../utils/transformDate';

export default {
  name: 'search-result-table',
  components: {
    Ticket,
    Pagination
  },
  props: {
    title: String,
    users: Array,
    isCloseCollapse: Boolean
  },
  data() {
    return {
      page: 1,
      showItemsCount: 20,
      currentUser: null,
      disabledTable: false,
      collapse: null,
      copyBtnLabel: "Скопировать",
      copiedBtn: false
    };
  },
  computed: {
    pageCount() {
      return pageCount(this.users, this.showItemsCount);
    },
    sliceData() {
      return allItems(this.users, this.pageCount, this.showItemsCount);
    },
    items() {
      if (this.page === 0) {
        return this.sliceData[0];
      }
      return this.sliceData[this.page - 1];
    }
  },
  watch: {
    pageCount(value) {
      this.page = this.page > value ? value : this.page;
    },
    isCloseCollapse(value) {
      if (value && this.collapse) {
        let activeItemIndex = null;
        this.collapse.el.children.forEach((listItem, index) => {
          if (listItem.classList.contains('active')) {
            activeItemIndex = index;
          }
        });

        this.collapse.close(activeItemIndex);
        this.disabledTable = false;
      }
    },
    users(value) {
      if (value) {
        this.$nextTick(() => {
          if (this.collapse && this.collapse.destroy) {
            this.collapse.destroy();
          }
          this.collapse = M.Collapsible.init(this.$refs.collapse, {});
        });
      }
    }
  },
  methods: {
    getPrefix(title) {
      if (title.includes('alfa')) {
        return 'alfa';
      }
      if (title.includes('sber')) {
        return 'sber';
      }
      if (title.includes('ukr')) {
        return 'ukr';
      }
      if (title.includes('dadim')) {
        return 'dadim';
      }
      if (title.includes('delta')) {
        return 'delta';
      }
    },
    setActiveClass(userId) {
      this.$emit('toggle-show', userId, this.title);

      if (this.currentUser !== userId) {
        if (
          this.$route.query.id != userId ||
          this.$route.query.base !== this.title
        ) {
          this.$router.replace(`/?id=${userId}&base=${this.title}`);
        }
        this.currentUser = userId;
        this.disabledTable = true;
        return;
      }
      if (Object.keys(this.$route.query).length) {
        this.$router.replace(`/`);
      } else {
        this.$router.replace(`/?id=${userId}&base=${this.title}`);
      }
      this.disabledTable = !this.disabledTable;
    },
    getVerificateDate(userTransactions) {
      if (!userTransactions.length) {
        return '';
      }
      let verificateUser = userTransactions.find(
        transaction => transaction.ispay == 1
      );
      if (verificateUser) {
        return getYMDFormatDate(verificateUser.createdat);
      }
      return '';
    },
    changePageHandler(page) {
      this.disabledTable = false;
      this.page = page;
    },
    getCapitalLetter(word) {
      return getCapitalLetter(word);
    },
    async copyFirstFields(id, name ,phone) {
      const textToCopy = `${id}\n${name}\n${phone}` 
      await navigator.clipboard.writeText(textToCopy);
      this.copiedBtn = true;
      this.copyBtnLabel = "Скопировано";

      setTimeout(()=> {
        this.copyBtnLabel = "Скопировать";
        this.copiedBtn = false;
      }, 1500)
    }
  },
  mounted() {
    /* global M */
    this.collapse = M.Collapsible.init(this.$refs.collapse, {});
  }
};
</script>

<style lang="scss" scoped>
@media (max-width: 600px) {
  .base_wrapper {
    overflow-x: auto;
    .result_table_wrapper {
      min-width: 600px;
    }
  }
}

.disabled-table {
  li:not(.active) {
    opacity: 0.6;
  }
}

.collapsible {
  margin-top: 0;
  min-width: 500px;
  border: 0;
  box-shadow: 0 0 0 0;
}
.collapsible-header {
  border: 0;
  padding: 0;
}

.collapsible-body {
  padding: 0;
  border-bottom: none;
}

.shadow_body {
  margin: 10px 0;
  padding: 5px;
  box-shadow: 0 0 10px 5px #4cafa640;
  &.alfa {
    box-shadow: 0 0 10px 5px #d048482e;
  }
  &.sber {
    box-shadow: 0 0 10px 5px #1a841e24;
  }
  &.ukr {
    box-shadow: 0 0 10px 5px #bdbf3647;
  }
  &.dadim {
    box-shadow: 0 0 10px 5px #266cd62e;
  }
  &.delta {
    box-shadow: 0 0 10px 5px #279c9c2e;
  }
}

.wrapper_grid {
  display: grid;
  grid-template-columns: 55px 1fr 1fr 1fr 1fr 75px;
  font-size: 13px;
  &.header_grid {
    &.alfa div {
      background-color: #d04848;
    }
    &.sber div {
      background-color: #1a841e;
    }
    &.ukr div {
      background-color: #bdbf36;
    }
    &.dadim div {
      background-color: #266cd6;
    }
    &.delta div {
      background-color: #279c9c;
    }
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      background: #395992;
      color: white;
      font-weight: 500;
    }
  }
  &.item_grid {
    &:hover:not(.activeStatus):not(.errorStatus) {
      background: #005aff12;
    }
    &.activeStatus,
    &.errorStatus {
      &:hover {
        backdrop-filter: brightness(95%);
      }
    }
    div {
      padding: 2px;
    }
  }
  div {
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
}
.activeStatus {
  background: #0080003b;
}

.errorStatus {
  background: #ff00004d;
}

.ticket-leave-active {
  transition-duration: 0.5s;
}
.ticket-enter-active {
  transition-duration: 1s;
}

button {
  margin-top: 4px;
  display: inline-flex;
  background: #fff;
  width: 110px;
  justify-content: center;
  color: #222;
  padding: 0.3rem;
  border: 1px solid #222;;
  transition: 0.4s ease-in-out;
  cursor: pointer;
  &:active, &:focus, &:focus-within {
    background: #fff;
  }
}

.successed-copied-btn {
  background: #1e9277;
  color: #fff;
  transition: 0.4s ease-in-out;
  pointer-events: none;
  border: 1px solid #1e9277;
  &:active, &:focus, &:focus-within {
    background: #1e9277;
  }
}
</style>
