<template>
  <div id="modal5" class="modal" ref="modal">
    <div class="modal-content">
      <h5>История SMS:</h5>
      <div class="table_wrapper">
        <table v-if="sms.length" class="highlight">
          <thead>
            <tr>
              <th>Дата</th>
              <th>Сообщение</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="message of sms" :key="message.id">
              <td>
                {{ message.send_date || getFullFormat(message.send_at) }}
              </td>
              <td>{{ message.msg || message.message }}</td>
            </tr>
          </tbody>
        </table>
        <p v-else>Сообщений не найдено</p>
      </div>
    </div>
  </div>
</template>

<script>
import { getFullFormatWithSecDate } from '../../../utils/transformDate';
export default {
  name: 'sms-modal',
  props: {
    sms: Array
  },
  data() {
    return {
      modal: null
    };
  },
  methods: {
    getFullFormat(data) {
      return getFullFormatWithSecDate(data);
    }
  },
  mounted() {
    /* global M */
    this.modal = M.Modal.init(this.$refs.modal, {
      onCloseEnd: () => {
        this.$emit('destroy-sms-modal');
      }
    });
    this.modal.open();
  },
  beforeDestroy() {
    if (this.modal && this.modal.destroy) {
      this.modal.destroy();
    }
  }
};
</script>
