export let deepClone = function(obj) {
  var copy;
  // Обработка трёх простых типов и null или undefined
  if (null == obj || 'object' != typeof obj) return obj;
  // Обработка дат
  if (obj instanceof Date) {
    copy = new Date();
    copy.setTime(obj.getTime());
    return copy;
  }
  // Обработка массивов
  if (obj instanceof Array) {
    copy = [];
    for (var i = 0, len = obj.length; i < len; i++) {
      copy[i] = deepClone(obj[i]);
    }
    return copy;
  }
  // Обработка функций
  if (obj instanceof Function) {
    copy = function() {
      return obj.apply(this, arguments);
    };
    return copy;
  }
  // Обработка объектов
  if (obj instanceof Object) {
    copy = {};
    for (var attr in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(attr)) copy[attr] = deepClone(obj[attr]);
    }
    return copy;
  }
  throw new Error(
    "Unable to copy obj as type isn't supported " + obj.constructor.name
  );
};
