<template>
  <div class="button-rule">
    <button
      v-if="userStatus !== 'operator'"
      class="btn waves-effect waves-light remove"
      @click="clearUserInfo(ticket.id, target)"
      :disabled="
        !isActiveTicket ||
          ticket.subscription_id.status === 'active' ||
          ticket.isactive === 0
      "
      title="Для этого действия нужен активный тикет и отсутствие подписки"
    >
      {{ ticket.isactive === 1 ? 'Удалить ПД' : 'ПД не используются' }}
    </button>
    <button
      v-if="userStatus !== 'operator'"
      class="btn waves-effect waves-light unsub"
      @click="unsubscribeUser(ticket, target)"
      :disabled="!isActiveTicket"
      title="Для этого действия нужен активный тикет"
    >
      Отмена подписки
    </button>
    <button
      v-if="userStatus !== 'operator'"
      class="btn waves-effect waves-light unsub_sms"
      @click="cancelSms(ticket.contact.phonesearch)"
      :disabled="!isActiveTicket"
      title="Для этого действия нужен активный тикет"
    >
      Отписать от СМС
    </button>
    <button
      class="btn waves-effect waves-light sms"
      @click="getSmsHistory(ticket, target)"
    >
      История СМС
    </button>
    <button
      class="btn waves-effect waves-light center-sms"
      @click="getCenterSmsHistory(ticket.contact.phonesearch, ticket.createdat)"
    >
      Центр СМС
    </button>
    <button
      v-if="ticket.acquiring.toLowerCase().includes('cloud')"
      class="btn waves-effect waves-light sub"
      @click="getSubscribeData(target, ticket)"
      :disabled="!Object.keys(ticket.subscription_id).length"
    >
      Подписки CLOUD
    </button>
    <button
      v-if="
        userStatus !== 'operator' && Object.keys(ticket.subscription_id).length
      "
      class="btn waves-effect waves-light ecvair"
      @click="getAcquiring(target, ticket.acquiring, ticket.id)"
    >
      Эквайринг
    </button>
    <button
      v-if="
        userStatus !== 'operator' && Object.keys(ticket.subscription_id).length
      "
      class="btn waves-effect waves-light generate"
      @click="generatePackage(target, ticket.acquiring, ticket.id)"
    >
      Генерировать пакет
    </button>

    <SmsModal
      v-if="isShowSms"
      @destroy-sms-modal="isShowSms = false"
      :sms="sms"
    />
    <SubscribeModal
      v-if="isShowSubscribe"
      @destroy-subs-modal="isShowSubscribe = false"
      :subs="subscribe"
    />
  </div>
</template>

<script>
import SubscribeModal from '../Ticket/SubscribeModal';
import SmsModal from '../Ticket/SmsModal';
import { getDMYFormatDate } from '../../../utils/transformDate';
import API from '../../../utils/API';
export default {
  name: 'ticket-buttons',
  components: {
    SubscribeModal,
    SmsModal
  },
  props: {
    ticket: Object,
    target: String,
    isActiveTicket: Boolean,
    activeTicket: Number,
    userStatus: String
  },
  data() {
    return {
      API: new API(),
      sms: null,
      subscribe: [],
      isShowSubscribe: false,
      isShowSms: false
    };
  },
  methods: {
    async clearUserInfo(id, target) {
      this.$store.commit('toggleLoad', true);
      const response = await this.API.clearUserInfo({ id, target });

      if (response.result.success) {
        /* global M */
        M.toast({
          html: `[Удаление из БД успешно!]`,
          classes: 'teal darken-1',
          displayLength: 5000
        });

        this.$emit('refresh-data');

        const updateData = {
          ticket_id: this.activeTicket,
          comment: 'Успешное удаление данных пользователя из БД'
        };
        const response_update = await this.API.updateTicket(updateData);
        if (response_update.result.success) {
          M.toast({
            html: `[Запись о удаление добавлена в тикет ${this.activeTicket}]`,
            classes: 'teal darken-1',
            displayLength: 5000
          });
        }
      } else {
        M.toast({
          html: `[Ошибка]: ${response.result.message}`,
          classes: 'red darken-3',
          displayLength: 5000
        });
      }
      this.$store.commit('toggleLoad', false);
    },
    async unsubscribeUser(id, target) {
      this.$store.commit('toggleLoad', true);
      const {
        acquiring,
        contact: { phonesearch }
      } = id;
      const response = await this.API.unsubscribeUser({
        phonesearch,
        acquiring,
        target
      });

      if (response.result.success) {
        M.toast({
          html: `[Отписка успешна!]`,
          classes: 'teal darken-1',
          displayLength: 5000
        });

        this.$emit('refresh-data');

        const updateData = {
          ticket_id: this.activeTicket,
          comment: 'Успешная отписка пользователя'
        };
        const response_update = await this.API.updateTicket(updateData);
        if (response_update.result.success) {
          M.toast({
            html: `[Запись о отписке пользователя добавлена в тикет ${this.activeTicket}]`,
            classes: 'teal darken-1',
            displayLength: 10000
          });
        }
      } else {
        M.toast({
          html: `[Ошибка]: ${response.result.message}`,
          classes: 'red darken-3',
          displayLength: 5000
        });
      }
      this.$store.commit('toggleLoad', false);
    },
    async getSmsHistory(ticket, target) {
      this.$store.commit('toggleLoad', true);
      const {
        id,
        contact: { phonesearch }
      } = ticket;
      const response = await this.API.getSmsHistory({
        id,
        phonesearch,
        target
      });

      if (response.result.success) {
        this.sms = response.result.message;
        this.isShowSms = true;
      } else {
        M.toast({
          html: `[Ошибка]: ${response.result.message[0]}`,
          classes: 'red darken-3',
          displayLength: 5000
        });
      }
      this.$store.commit('toggleLoad', false);
    },
    async getCenterSmsHistory(phone, rawDate) {
      this.$store.commit('toggleLoad', true);
      const date = getDMYFormatDate(rawDate);
      const response = await this.API.getCenterSmsHistory({ phone, date });

      if (response.result.success) {
        this.sms = response.result.message;
        this.isShowSms = true;
      } else {
        M.toast({
          html: `[Ошибка]: ${response.result.message[0]}`,
          classes: 'red darken-3',
          displayLength: 5000
        });
      }
      this.$store.commit('toggleLoad', false);
    },
    async cancelSms(phone) {
      this.$store.commit('toggleLoad', true);
      const response = await this.API.cancelSms({ phone });

      if (response.result.success) {
        M.toast({
          html: `[Отписка от СМС успешна!]`,
          classes: 'teal darken-1',
          displayLength: 5000
        });
        const updateData = {
          ticket_id: this.activeTicket,
          comment: 'Успешная отписка от СМС рассылки'
        };
        const response_update = await this.API.updateTicket(updateData);
        if (response_update.result.success) {
          M.toast({
            html: `[Запись о отписке от СМС добавлена в тикет ${this.activeTicket}]`,
            classes: 'teal darken-1',
            displayLength: 10000
          });
        }
      } else {
        M.toast({
          html: `[Ошибка]: ${response.result.message[0]}`,
          classes: 'red darken-3',
          displayLength: 5000
        });
      }

      this.$store.commit('toggleLoad', false);
    },
    async getSubscribeData(base, userData) {
      this.$store.commit('toggleLoad', true);

      const response = await this.API.getSubscribeData(base, userData.id);
      if (response.result.success) {
        this.subscribe = response.result.model;
        this.isShowSubscribe = true;
      } else {
        M.toast({
          html: `[Ошибка]: Ошибка при обработке ответа`,
          classes: 'red darken-3',
          displayLength: 5000
        });
      }
      this.$store.commit('toggleLoad', false);
    },

    async getAcquiring(target_base, acquiring, questionnaire_id) {
      this.$store.commit('toggleLoad', true);
      await this.API.getAcquiring({
        target_base,
        acquiring,
        questionnaire_id
      });
    },

    async generatePackage(target_base, acquiring, questionnaire_id) {
      this.$store.commit('toggleLoad', true);

      await this.API.generatePackage({
        target_base,
        acquiring,
        questionnaire_id
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.button-rule {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  @media (min-width: 1010px) {
    flex-wrap: none;
  }

  button {
    min-width: 120px;
    width: 10%;
    flex-grow: 1;
    font-size: 10px;
    height: 26px;
    line-height: 26px;
    padding: 2px;
    margin: 2px;
    @media (min-width: 1010px) {
      min-width: 120px;
    }

    &.remove {
      background: #a7251b;
    }
    &.unsub {
      background: #ce5a07e0;
    }
    &.unsub_sms {
      background: #dca43f;
    }
    &.sms {
      background: #2b882e;
    }
    &.sub {
      background: #40bdac;
    }
    &.ecvair {
      background: #853b96;
    }
    &.generate {
      background: #3a537b;
    }
  }
}
</style>
