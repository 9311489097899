import Vue from 'vue';
import Vuex from 'vuex';
import search from './modules/search';
import tickets from './modules/tickets';
import controller from './modules/controller';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    load: false,
    submit: false,
    error: null
  },
  mutations: {
    toggleLoad(state, loadStatus) {
      state.load = loadStatus;
    },
    toggleSubmit(state, loadStatus) {
      state.submit = loadStatus;
    },
    setError(state, error) {
      state.error = error;
    },
    clearError(state) {
      state.error = null;
    }
  },
  actions: {},
  getters: {
    error: s => s.error
  },
  modules: {
    search,
    tickets,
    controller
  }
});
