<template>
  <div class="ticket_update_grid">
    <button
      class="checkbox_btn btn btn-floating"
      title="Сортировка по дате"
      :class="{ date_sort: isActiveSort }"
      @click="sortByDate"
      :disabled="!tickets.length"
    >
      <i class="material-icons">schedule</i>
    </button>
    <table class="highlight">
      <thead>
        <tr>
          <th>ID тикета</th>
          <th>Оператор</th>
          <th>Дата</th>
          <th>Статус</th>
          <th class="control">Управление</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="ticket of tickets"
          :key="ticket.id"
          @click="createUpdateModal(ticket)"
        >
          <td>{{ ticket.id }}</td>
          <td>
            {{
              `${ticket.operator && `#${ticket.operator}`} ${
                ticket.auth_user.username
              }`
            }}
          </td>
          <td>
            {{
              ticket.create_ticket ? transformDate(ticket.create_ticket) : ''
            }}
          </td>
          <td>{{ statuses[ticket.status] }}</td>
          <td>
            <button
              v-if="!!ticket.id && ticket.status != 1"
              class="btn fast-close"
              @click.stop="fastCloseTicket(ticket.id)"
            >
              Исполнить
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="btn-wrapper">
      <button
        class="btn waves-effect waves-light button-create-ticket"
        v-if="!tickets.length || tickets.every(it => it.status === 1)"
        @click="isForm = true"
      >
        Создать тикет
      </button>
      <button
        class="btn waves-effect waves-light button-create-new-ticket"
        v-if="!tickets.length || tickets.every(it => it.status === 1)"
        @click="createNewTicket"
      >
        Новый тикет
      </button>
    </div>

    <TicketForm
      v-if="isForm"
      :ticket="ticket"
      :id="id"
      :target="target"
      @destroy-form="destroyForm()"
      @refresh-tickets="getTicketsInBase()"
    />
  </div>
</template>

<script>
import API from '../../../utils/API';
import TicketForm from '../Ticket/TicketForm';
import { getFullFormatDate } from '../../../utils/transformDate';
import { mapTicketStatus } from '../../../utils/mapTicketStatus';
export default {
  name: 'ticket-update-table',
  components: {
    TicketForm
  },
  props: {
    target: String,
    id: Number
  },
  data() {
    return {
      isUpdateForm: false,
      isCreateForm: false,
      isForm: false,
      ticket: null,
      tickets: [],
      role: null,
      statuses: mapTicketStatus,
      isActiveSort: false
    };
  },
  methods: {
    async getTicketsInBase() {
      this.$store.commit('toggleLoad', true);

      const kabinte_api = new API();
      const response = await kabinte_api.getTicketsInBase({
        target: this.target,
        id: this.id
      });

      this.tickets = response.result.message.tickets;
      this.role = response.result.role;
      this.$emit('set-role', this.role);

      if (!this.tickets.length) {
        this.tickets = {
          ticket: {
            id: '',
            auth_user: { username: '' },
            operator: '',
            create_ticket: '',
            status: ''
          }
        };
      } else {
        const activeTicket = this.tickets.find(it => it.status !== 1);
        this.$emit(
          'set-active-ticket',
          activeTicket,
          activeTicket !== undefined
        );
        const chargeTicket = this.tickets.find(it => it.status === 5);
        this.$emit(
          'set-charge-ticket',
          chargeTicket,
          chargeTicket !== undefined
        );
      }

      this.$store.commit('toggleLoad', false);
    },
    async createNewTicket() {
      this.$store.commit('toggleLoad', true);

      const kabinte_api = new API();
      const response = await kabinte_api.createTicket({
        id: this.id,
        target: this.target,
        status: 0,
        comment: 'Создан новый тикет',
        file: ''
      });

      if (response.result.success) {
        M.toast({
          html: `[Тикет успешно создан!]`,
          classes: 'teal darken-1',
          displayLength: 5000
        });

        await this.getTicketsInBase();
        this.$store.commit('toggleLoad', false);
      } else {
        /*global M*/
        M.toast({
          html: `[Ошибка]: Тикет не создан`,
          classes: 'red darken-3',
          displayLength: 5000
        });

        this.$store.commit('toggleLoad', false);
      }
    },
    async fastCloseTicket(id) {
      const kabinte_api = new API();

      const response = await kabinte_api.updateTicket({
        ticket_id: id,
        status: 1,
        comment: 'Тикет успешно закрыт'
      });

      if (response.result.success) {
        M.toast({
          html: `[Тикет успешно закрыт!]`,
          classes: 'teal darken-1',
          displayLength: 5000
        });

        await this.getTicketsInBase();
        this.$store.commit('toggleLoad', false);
      } else {
        M.toast({
          html: `[Ошибка]: Тикет не создан`,
          classes: 'red darken-3',
          displayLength: 5000
        });
        this.$store.commit('toggleLoad', false);
      }
    },
    createUpdateModal(ticket) {
      if (!ticket.id) {
        return;
      }
      this.ticket = ticket;
      this.isForm = true;
    },
    transformDate(date) {
      return getFullFormatDate(date);
    },
    destroyForm() {
      this.isForm = false;
      this.ticket = null;
    },
    sortByDate() {
      if (this.isActiveSort) {
        this.tickets.sort((a, b) => {
          return (
            new Date(a.create_ticket).valueOf() -
            new Date(b.create_ticket).valueOf()
          );
        });
        this.isActiveSort = false;
      } else {
        this.tickets.sort((a, b) => {
          return (
            new Date(b.create_ticket).valueOf() -
            new Date(a.create_ticket).valueOf()
          );
        });
        this.isActiveSort = true;
      }
    }
  },
  created() {
    this.getTicketsInBase();
  }
};
</script>

<style lang="scss" scoped>
th.control {
  width: 100px;
}

.fast-close {
  height: 20px;
  font-size: 10px;
  line-height: 10px;
  width: 100%;
}

.ticket_update_grid {
  display: flex;
  flex-direction: column;
  position: relative;

  button {
    &.checkbox_btn {
      position: absolute;
      top: -32px;
      left: 110px;
      height: 24px;
      width: 24px;
      line-height: 24px;
      background: #bdbdbd;
      color: #3c4b64;
      font-size: 12px;
    }
    &.date_sort {
      background: #4caf50;
      color: #fff;
    }
    i {
      font-size: 20px;
      line-height: 24px;
    }
  }

  .btn-wrapper {
    width: 100%;
    display: flex;
  }

  .button-create-ticket {
    margin: 15px 20px 10px 0;
    width: 75%;
    background-color: #1e9277;
  }

  .button-create-new-ticket {
    margin: 15px 0 10px 0;
    width: 25%;
    background-color: #1e9277;
  }
}
</style>
