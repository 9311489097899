<template>
  <nav class="app-navbar white">
    <div class="nav-wrapper">
      <div class="navbar-left">
        <a @click="$emit('click')">
          <i class="material-icons theme-color-text">menu</i>
        </a>
      </div>

      <ul class="dropdown-user">
        <li>
          <a
            class="dropdown-trigger theme-color-text user-avatar"
            href="#"
            data-target="dropdown"
            ref="dropdown"
            >{{ name.toUpperCase() }}</a
          >

          <ul id="dropdown" class="dropdown-content">
            <li v-if="!isProfile && isAdmin">
              <a href="#" class="theme-color-text" @click="profile">
                <i class="material-icons">account_circle</i>Профиль
              </a>
            </li>
            <li v-if="isProfile">
              <router-link to="/" class="theme-color-text">
                <i class="material-icons">search</i>Поиск
              </router-link>
            </li>
            <li class="divider" tabindex="-1"></li>
            <li>
              <a href="#" class="theme-color-text" @click.prevent="logout">
                <i class="material-icons">assignment_return</i>Выйти
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'navbar',
  props: {
    isProfile: Boolean,
    isAdmin: Boolean,
    name: String
  },
  data() {
    return {
      dropdown: null,
      displaySearch: false
    };
  },
  methods: {
    logout() {
      localStorage.clear();
      this.$store.commit('clearResult');
      this.$store.commit('clearTickets');
      this.$router.push('/login');
    },
    profile() {
      localStorage.removeItem('funcName');
      localStorage.removeItem('funcArgs');
      this.$store.commit('clearResult');
      this.$store.commit('clearTickets');
      this.$router.push('/profile');
    }
  },
  mounted() {
    /*global M*/
    this.dropdown = M.Dropdown.init(this.$refs.dropdown, {
      coverTrigger: false,
      constrainWidth: false
    });
  },
  beforeDestroy() {
    clearInterval(this.interval);
    if (this.dropdown && this.dropdown.destroy) {
      this.dropdown.destroy();
    }
  }
};
</script>

<style lang="scss" scoped>
.theme-color-text {
  cursor: pointer;
}
</style>
