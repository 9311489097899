const pageCount = function(array, pageSize = 3) {
  return Math.ceil(array.length / pageSize);
};
const sliceData = function(array, count, pageSize = 3) {
  let constArray = [];
  for (let i = 0; i < count; i++) {
    constArray[i] = array.slice(i * pageSize, i * pageSize + pageSize);
  }
  return constArray;
};

export { pageCount, sliceData };
