const mapTicketStatus = {
  '0': 'новый',
  '1': 'исполнено',
  '2': 'на уточнении',
  '3': 'чаржбэк',
  '4': 'не найден',
  '5': 'возврат'
};

const mapRefundStatus = {
  '0': 'default',
  '1': 'возвращен',
  '2': 'к возврату'
};

export { mapTicketStatus, mapRefundStatus };
