<template>
  <div class="payment_wrapper">
    <button
      class="checkbox_btn btn btn-floating"
      title="Фильтрация по charge"
      :class="{ charge_filter: isChargeFiltred }"
      @click="filteredPaymentByCharge"
      :disabled="!historyItems"
    >
      <i class="material-icons">attach_money</i>
    </button>
    <table v-if="!loaderMini" class="highlight">
      <thead>
        <tr>
          <th class="click" :class="isDateSorted && 'sort'" @click="sortByDate">
            Дата
          </th>
          <th>Номер</th>
          <th>Статус</th>
          <th>Сумма</th>
          <th>Возврат</th>
          <th>Действие</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="payment of filtredPayments" :key="payment.createdat">
          <td>
            {{ payment.createdat ? transformDate(payment.createdat) : '' }}
          </td>
          <td>{{ payment.id }}</td>
          <td>{{ payment.status }}</td>
          <td>
            {{
              payment.status.toLowerCase() === 'charged' ? payment.amount : ''
            }}
          </td>
          <td>{{ mapRefundStatus[payment.refund] }}</td>
          <td>
            <div v-if="payment.status.toLowerCase() === 'charged'">
              <button
                v-if="
                  userStatus !== 'admin' &&
                    (payment.refund === 0 || payment.refund === null)
                "
                @click="changeRefundStatus(payment.id, target, '2')"
                class=" pay-action request waves-effect"
                :disabled="!isChargeTicket"
                :class="{
                  disabled: !isChargeTicket
                }"
              >
                К возврату
              </button>
              <button
                v-if="userStatus === 'unsubscriber' && payment.refund === 2"
                @click="changeRefundStatus(payment.id, target, '0')"
                class=" pay-action request waves-effect"
                :disabled="!isChargeTicket"
                :class="{
                  disabled: !isChargeTicket
                }"
              >
                Отмена
              </button>

              <button
                v-if="
                  userStatus === 'admin' &&
                    (payment.refund === 0 || payment.refund === null)
                "
                class=" pay-action refund waves-effect"
                @click="getRefund(payment, target, acquiring)"
                :disabled="!isChargeTicket"
                :class="{
                  disabled: !isChargeTicket
                }"
              >
                Возврат
              </button>
              <div
                class="half-btn-wrapper"
                v-if="userStatus === 'admin' && payment.refund === 2"
              >
                <button
                  v-if="userStatus === 'admin' && payment.refund === 2"
                  @click="getRefund(payment, target, acquiring)"
                  class=" pay-action half refund waves-effect"
                  :disabled="!isChargeTicket"
                  :class="{
                    disabled: !isChargeTicket
                  }"
                >
                  Возврат
                </button>
                <button
                  v-if="userStatus === 'admin' && payment.refund === 2"
                  @click="changeRefundStatus(payment.id, target, '0')"
                  class="pay-action half cansel waves-effect"
                  :disabled="!isChargeTicket"
                  :class="{
                    disabled: !isChargeTicket
                  }"
                >
                  Отмена
                </button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <LoaderMini v-else />
  </div>
</template>

<script>
import LoaderMini from '../LoaderMini';
import API from '../../../utils/API';
import { mapRefundStatus } from '../../../utils/mapTicketStatus';
import { deepClone } from '../../../utils/deepClone';
import { getFullFormatDate } from '../../../utils/transformDate';
export default {
  name: 'ticket-payment',
  components: {
    LoaderMini
  },
  props: {
    payments: Object,
    userStatus: String,
    id: Number,
    target: String,
    acquiring: String,
    isChargeTicket: Boolean,
    chargeTicket: Number
  },
  data() {
    return {
      API: new API(),
      mapRefundStatus,
      loaderMini: true,
      historyItems: [],
      filterHistoryItems: [],
      isChargeFiltred: false,
      isDateSorted: false,
      isUnsubStatus: false
    };
  },
  computed: {
    filtredPayments() {
      if (this.filterHistoryItems === undefined) {
        return {
          payment: {
            createdat: '',
            status: '',
            amount: ''
          }
        };
      }
      const filtredResult = this.filterHistoryItems.filter(payment => {
        return (
          payment.status.toLowerCase() === 'nomany' ||
          payment.status.toLowerCase() === 'charged' ||
          payment.status.toLowerCase() === 'fail'
        );
      });
      if (!filtredResult.length) {
        return {
          payment: {
            createdat: '',
            status: '',
            amount: ''
          }
        };
      }
      return filtredResult;
    }
  },
  methods: {
    async getRefund(paymentrequrrent, target, acquiring) {
      // console.log(this);
      // const _this = this;
      // let prefix = '';
      // let paymentData = {};

      // if (this.acquiring.toLowerCase().includes('cloud')) {
      //   prefix = 'getRefundCloud';
      //   paymentData = {
      //     target,
      //     acquiring,
      //     paymentrequrrent
      //   };
      // }

      // if (this.acquiring.toLowerCase().includes('tinkoff')) {
      //   prefix = 'getRefundTinkoff';
      //   paymentData = {
      //     target,
      //     acquiring,
      //     paymentrequrrent,
      //     questionnaire_id: this.id
      //   };
      // }

      const paymentData = {
        target,
        acquiring,
        paymentrequrrent,
        questionnaire_id: this.id
      };

      // if (prefix) {
      this.$store.commit('toggleLoad', true);

      // const response = await this.API[prefix](paymentData);
      const response = await this.API.getRefund(paymentData);

      if (response.result.success) {
        /* global M */
        M.toast({
          html: `Платеж успешно возвращен`,
          classes: 'teal darken-1',
          displayLength: 5000
        });

        await this.getRecurrents();

        const receiptData = {
          target_base: target,
          questionnaire_id: this.id,
          payment_requrrent: paymentrequrrent
        };
        const response_receipt = await this.API.getReceipt(receiptData);
        if (response_receipt.result.success) {
          M.toast({
            html: `Чек успешно сформирован`,
            classes: 'teal darken-1',
            displayLength: 5000
          });
        }
        const updateData = {
          ticket_id: this.chargeTicket,
          status: 5,
          comment: 'Успешный возврат сердств'
        };
        const response_update = await this.API.updateTicket(updateData);
        if (response_update.result.success) {
          M.toast({
            html: `[Запись о возврате средств добавлена в тикет ${this.chargeTicket}]`,
            classes: 'teal darken-1',
            displayLength: 10000
          });
        }
      } else {
        M.toast({
          html: `Ошибка возрвата платежа`,
          classes: 'red darken-3',
          displayLength: 5000
        });
      }
      // } else {
      //   M.toast({
      //     html: `Возврат не возможен, т.к. не поддерживает acquiring [${this.acquiring}]`,
      //     classes: 'red darken-3',
      //     displayLength: 5000
      //   });
      // }
      this.$store.commit('toggleLoad', false);
    },
    async changeRefundStatus(id, target, status) {
      const paymentData = {
        base: target,
        id,
        status
      };

      this.$store.commit('toggleLoad', true);
      const response = await this.API.changeRefundStatus(paymentData);
      this.$store.commit('toggleLoad', false);

      if (response.result.success) {
        M.toast({
          html: `Статус палтежа успешно изменен на "${mapRefundStatus[status]}"`,
          classes: 'teal darken-1',
          displayLength: 5000
        });

        await this.getRecurrents();

        const updateData = {
          ticket_id: this.chargeTicket,
          status: 5,
          comment: 'Успешная смена статуса возврата'
        };
        const response_update = await this.API.updateTicket(updateData);
        if (response_update.result.success) {
          M.toast({
            html: `[Запись о статусе возвзрата добавлена в тикет ${this.chargeTicket}]`,
            classes: 'teal darken-1',
            displayLength: 10000
          });
        }
      } else {
        M.toast({
          html: `Статус палтежа не изменен`,
          classes: 'red darken-3',
          displayLength: 5000
        });
      }
    },
    transformDate(date) {
      return getFullFormatDate(date);
    },
    filteredPaymentByCharge() {
      if (!this.isChargeFiltred) {
        this.isChargeFiltred = true;
        let filtredResult = this.filterHistoryItems.filter(payment => {
          return payment.status.toLowerCase() === 'charged';
        });
        if (!filtredResult.length) {
          this.filterHistoryItems = undefined;
        } else {
          this.filterHistoryItems = filtredResult;
        }
      } else {
        this.isChargeFiltred = false;
        this.filterHistoryItems = deepClone(this.historyItems);
      }
    },
    sortByDate() {
      if (!this.isDateSorted) {
        this.isDateSorted = true;
        this.filterHistoryItems.sort((a, b) => {
          const firstTime = new Date(a.createdat).getTime();
          const secondTime = new Date(b.createdat).getTime();
          return secondTime - firstTime;
        });
      } else {
        this.isDateSorted = false;
        this.filterHistoryItems.sort((a, b) => {
          const firstTime = new Date(a.createdat).getTime();
          const secondTime = new Date(b.createdat).getTime();
          return firstTime - secondTime;
        });
      }
    },
    toggleUnsubStatus() {
      this.isUnsubStatus = !this.isUnsubStatus;
    },
    async getRecurrents() {
      this.loaderMini = true;

      const response = await this.API.getRecurrents(
        this.target,
        this.payments.id
      );
      this.loaderMini = false;
      if (response.result.success) {
        this.historyItems = response.result.models;
      } else {
        this.historyItems = undefined;
      }
      this.filterHistoryItems = deepClone(this.historyItems);
    }
  },
  async created() {
    await this.getRecurrents();
  }
};
</script>

<style lang="scss" scoped>
th.click {
  cursor: pointer;
  &.sort {
    border-bottom: 2px solid green;
  }
}
.payment_wrapper {
  position: relative;
}
.half-btn-wrapper {
  height: 17px;
  display: flex;
  justify-content: space-between;
}

.pay-action {
  height: 17px;
  border: none;
  border-radius: 2px;
  color: white;
  width: 100%;
  padding: 1px;
  font-size: 11px;
  &.refund {
    background-color: #3877ea;
  }
  &.request {
    background-color: #4caf50;
  }
  &.cansel {
    background-color: #d04848;
  }
  &.half {
    width: 48%;
  }

  &.disabled {
    background: #eee;
  }
}

button {
  &.checkbox_btn {
    position: absolute;
    top: -32px;
    left: 110px;
    height: 24px;
    width: 24px;
    line-height: 24px;
    background: #bdbdbd;
    color: #3c4b64;
    font-size: 12px;
  }
  &.charge_filter {
    background: #4caf50;
    color: #fff;
  }
  i {
    font-size: 20px;
    line-height: 24px;
  }
}
</style>
