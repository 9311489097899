<template>
  <div class="app-main">
    <Loader v-if="$store.state.load" />
    <transition name="btn_up">
      <ButtonUp v-if="isShowBtnUp" @throw-page-up="throwPageUp" />
    </transition>
    <div class="main-layout">
      <Sidebar @click="isOpen = !isOpen" v-model="isOpen" :isProfile="true" />
      <div class="wrapper" :class="{ fullwidth: !isOpen }">
        <header>
          <Navbar @click="isOpen = !isOpen" :isProfile="true" name="admin" />
        </header>
        <section
          class="content-scroll"
          @scroll="showBtnUp"
          ref="content-scroll"
        >
          <main class="app-content">
            <div class="app-page">
              <router-view />
            </div>
          </main>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/app/Sidebar.vue';
import Navbar from '@/components/app/Navbar.vue';
import ButtonUp from '@/components/app/ButtonUp.vue';

export default {
  name: 'profile',
  components: {
    Sidebar,
    Navbar,
    ButtonUp
  },
  data() {
    return {
      isOpen: true,
      isShowBtnUp: false,
      isShowUser: true,
      timeToUp: null
    };
  },
  methods: {
    showBtnUp() {
      if (this.$refs['content-scroll'].scrollTop > 200) {
        this.isShowBtnUp = true;
      } else {
        this.isShowBtnUp = false;
      }
    },
    throwPageUp() {
      if (this.$refs['content-scroll'].scrollTop > 0) {
        this.$refs['content-scroll'].scrollBy(0, -150);
        this.timeToUp = setTimeout(() => {
          this.throwPageUp();
        }, 16);
      } else {
        clearTimeout(this.timeToUp);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.btn_up-enter-active,
.btn_up-leave-active {
  transition: opacity 0.5s;
}
.btn_up-enter,
.btn_up-leave-to {
  opacity: 0;
}
</style>
