import API from '../../utils/API';
export default {
  state: {
    result: null
  },
  mutations: {
    setResult(state, result) {
      state.result = result.message;
    },
    clearResult(state) {
      state.result = null;
    },
    filterResult(state, { key, filtredResult }) {
      state.result[key].users = filtredResult;
    }
  },
  actions: {
    async fetchGetResult({ commit }, { id, tel, pan, lastName, base }) {
      localStorage.setItem('funcName', 'fetchGetResult');
      let data = JSON.stringify({ id, tel, pan, lastName, base });
      localStorage.setItem('funcArgs', data);
      const kabinetAPI = new API();
      const response = await kabinetAPI.getQuestionary({
        id,
        tel,
        pan,
        lastName,
        base
      });
      const result = response.result;

      for (const base in result.message) {
        const element = result.message[base];
        element.users.forEach(it => {
          it.isShow = false;
        });
      }
      commit('setResult', result);
    },
    async fetchGetOrderId({ commit }, { orderId, base }) {
      localStorage.setItem('funcName', 'fetchGetOrderId');
      let data = JSON.stringify({ orderId, base });
      localStorage.setItem('funcArgs', data);
      const kabinetAPI = new API();
      const response = await kabinetAPI.getOrderId({
        orderId,
        base
      });
      const result = response.result;

      for (const base in result.message) {
        const element = result.message[base];
        element.users.forEach(it => {
          it.isShow = false;
        });
      }
      commit('setResult', result);
    }
  },
  getters: {}
};
