<template>
  <div class="header-grid">
    <h6>{{ headers.title }}</h6>
    <button @click="toggleShow" class="toggle">
      <i class="material-icons">{{
        headers.isShow ? 'arrow_drop_up' : 'arrow_drop_down'
      }}</i>
    </button>
  </div>
</template>

<script>
export default {
  name: 'ticket-headers',
  props: ['headers'],
  methods: {
    toggleShow() {
      this.$emit('click');
    }
  }
};
</script>

<style lang="scss" scoped>
button.toggle {
  background-color: transparent;
  border: none;
}

.header-grid {
  display: flex;
  .toggle {
    margin-top: 10px;
  }
}
</style>
