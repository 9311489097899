<template>
  <div>
    <TicketsZeroTable
      :id="0"
      :target="target"
      :ticketId="user.id"
      @set-active-ticket="setActiveTicket"
      @set-charge-ticket="setChargeTicket"
      @set-role="setRole"
    />
  </div>
</template>

<script>
import TicketsZeroTable from '../components/app/TicketZero/TicketsZeroTable';
export default {
  name: 'ticket',
  components: {
    TicketsZeroTable
  },
  props: {
    user: Object,
    target: String
  },
  data() {
    return {
      isActiveTicket: false,
      activeTicket: null,
      isChargeTicket: false,
      chargeTicket: null,
      userStatus: 'admin',
      updatePanel: {
        title: 'Тикеты',
        isShow: true
      }
    };
  },
  methods: {
    setActiveTicket(ticket, bool) {
      this.isActiveTicket = bool;
      if (ticket !== undefined) {
        this.activeTicket = ticket.id;
      }
    },
    setChargeTicket(ticket, bool) {
      this.isChargeTicket = bool;
      if (ticket !== undefined) {
        this.chargeTicket = ticket.id;
      }
    },
    setRole(role) {
      this.userStatus = role;
    }
  }
};
</script>
