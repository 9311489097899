<template>
  <div class="app-loader">
    <button
      v-if="$store.getters.isAbort"
      type="button"
      class="btn "
      @click="$store.commit('reject')"
    >
      Отменить запрос
    </button>
    <div class="loader">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  beforeDestroy() {
    this.$store.commit('clear');
    this.$store.commit('setAbort', false);
  }
};
</script>

<style lang="scss" scoped>
@keyframes loader {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.app-loader {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  z-index: 9999;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;

  .btn {
    background-color: #1e9277;
    margin-top: 30px;
    opacity: 0.6;
    transition: 0.3s;

    &:hover,
    &:focus {
      opacity: 0.9;
    }
  }

  .loader {
    display: block;
    position: relative;
    width: 80px;
    height: 80px;
    .dot {
      position: absolute;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #1e9277;
      animation: loader 1.2s linear infinite;
      &:nth-child(1) {
        top: 8px;
        left: 8px;
        animation-delay: 0s;
      }
      &:nth-child(2) {
        top: 8px;
        left: 32px;
        animation-delay: -0.4s;
      }
      &:nth-child(3) {
        top: 8px;
        left: 56px;
        animation-delay: -0.8s;
      }
      &:nth-child(4) {
        top: 32px;
        left: 8px;
        animation-delay: -0.4s;
      }
      &:nth-child(5) {
        top: 32px;
        left: 32px;
        animation-delay: -0.8s;
      }
      &:nth-child(6) {
        top: 32px;
        left: 56px;
        animation-delay: -1.2s;
      }
      &:nth-child(7) {
        top: 56px;
        left: 8px;
        animation-delay: -0.8s;
      }
      &:nth-child(8) {
        top: 56px;
        left: 32px;
        animation-delay: -1.2s;
      }
      &:nth-child(9) {
        top: 56px;
        left: 56px;
        animation-delay: -1.6s;
      }
    }
  }
}
</style>
