<template>
  <div class="app-main">
    <Loader v-if="$store.state.load" />

    <div class="main-layout">
      <Sidebar
        @click="isOpen = !isOpen"
        v-model="isOpen"
        :bases="bases"
        :operators="operators"
      />
      <div class="wrapper" :class="{ fullwidth: !isOpen }">
        <header>
          <Navbar
            @click="isOpen = !isOpen"
            :isProfile="false"
            :isAdmin="isAdmin"
            :name="name"
          />
        </header>
        <section class="content-scroll">
          <main class="app-content">
            <div class="app-page">
              <SearchResult v-if="$store.state.search.result" />
              <SearchTicket v-else-if="$store.state.tickets.tickets" />

              <WelcomePage v-else />
            </div>
          </main>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/app/Sidebar.vue';
import Navbar from '@/components/app/Navbar.vue';
import WelcomePage from '../views/WelcomePage';
import SearchResult from '../views/SearchResult';
import SearchTicket from '../views/SearchTicket';
import API from '../utils/API';
export default {
  name: 'main-layout',
  components: {
    Sidebar,
    Navbar,
    SearchResult,
    SearchTicket,
    WelcomePage
  },
  data() {
    return {
      isOpen: true,
      isAdmin: false,
      name: '',
      bases: [],
      operators: {}
    };
  },
  async created() {
    if (Object.keys(this.$route.query).length) {
      let data = JSON.stringify({
        ...this.$route.query,
        base: [this.$route.query.base]
      });
      localStorage.setItem('funcArgs', data);
      localStorage.setItem('funcName', 'fetchGetResult');
    }

    this.$store.commit('toggleLoad', true);
    if (localStorage.getItem('funcName') === 'fetchGetResult') {
      await this.$store.dispatch(
        localStorage.getItem('funcName'),
        JSON.parse(localStorage.getItem('funcArgs'))
      );
    }

    if (localStorage.getItem('funcName') === 'fetchGetOrderId') {
      await this.$store.dispatch(
        localStorage.getItem('funcName'),
        JSON.parse(localStorage.getItem('funcArgs'))
      );
    }

    if (localStorage.getItem('funcName') === 'fetchGetTickets') {
      await this.$store.dispatch(
        localStorage.getItem('funcName'),
        JSON.parse(localStorage.getItem('funcArgs'))
      );
    }

    const cabinet_api = new API();
    const response = await cabinet_api.getOperatorsAndBasesInfo();
    this.name = response.result.role;
    if (response.result.message.db) {
      this.bases = response.result.message.db;
    }

    if (response.result.role === 'admin') {
      this.isAdmin = true;
    }

    if (response.result.message.users) {
      this.operators = response.result.message.users;
    }

    this.$store.commit('toggleLoad', false);
  }
};
</script>
