import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    meta: { layout: 'auth' },
    component: () => import('../views/Login.vue')
  },
  {
    path: '/',
    name: 'Main',
    meta: { layout: 'main', auth: true },
    component: () => import('../views/WelcomePage.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: { layout: 'profile', auth: true },
    component: () => import('../views/Profile.vue')
  },
  {
    path: '/role',
    name: 'Role',
    meta: { layout: 'profile', auth: true },
    component: () => import('../views/ProfileRole.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    meta: { layout: 'profile', auth: true },
    component: () => import('../components/app/Settings.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const currentUser = localStorage.getItem('auth');
  const requireAuth = to.matched.some(route => route.meta.auth);

  if (requireAuth && !currentUser) {
    next('/login');
  } else {
    next();
  }
});

export default router;
