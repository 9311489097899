<template>
  <div class="search-wrapper questionary">
    <h5>Поиск анкеты:</h5>
    <form
      @submit.prevent="submitHandler"
      @input="isInvalid = false"
      @change="isInvalid = false"
    >
      <div class="input-field">
        <the-mask
          id="id"
          name="id"
          type="text"
          autocomplete="off"
          placeholder="000000"
          v-model.trim="id"
          :disabled="!!orderId.length"
          mask="############"
        />
        <label for="id">ID анкеты</label>
      </div>
      <div class="input-field">
        <input
          id="tel"
          name="tel"
          type="tel"
          autocomplete="off"
          placeholder="+7(987)654-32-10"
          :disabled="!!orderId.length"
          v-model="tel"
        />
        <label for="tel">Телефон</label>
      </div>
      <div class="input-field">
        <the-mask
          id="pan"
          name="pan"
          type="text"
          autocomplete="off"
          placeholder="0000 00** **** 0000"
          :disabled="!!orderId.length"
          v-model="pan"
          mask="#### ##** **** ####"
          :masked="isPanShow"
        />
        <label for="pan">Pan</label>
      </div>
      <div class="input-field">
        <input
          id="lastName"
          name="lastName"
          type="text"
          autocomplete="off"
          placeholder="Ivanov"
          :disabled="!!orderId.length"
          v-model.trim="lastName"
        />
        <label for="lastName">Фамилия</label>
      </div>

      <div class="input-field">
        <input
          id="orderID"
          name="orderID"
          type="text"
          autocomplete="off"
          placeholder="0... или a0..."
          v-model="orderId"
        />
        <label for="orderID">ID платежа</label>
      </div>

      <div class="input-field">
        <select id="base" multiple ref="selectForm" v-model="base">
          <option value="" disabled>Все базы</option>
          <option v-for="base of bases" :key="base" :value="base">{{
            base
          }}</option>
        </select>
        <label for="base">Выберите базу</label>
      </div>
      <button
        class="btn waves-effect waves-light btn_submit"
        type="submit"
        :class="[{ invalid: isInvalid }, { shake: isShake }]"
        :disabled="$store.state.submit"
      >
        Поиск анкеты
        <i class="material-icons right">{{
          isInvalid ? 'report' : 'search'
        }}</i>
      </button>
      <span v-if="isInvalid" class="invalid">{{
        orderId ? '*Выберите одну базу данных' : '*Заполните одно из полей'
      }}</span>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { TheMask } from 'vue-the-mask';

export default {
  name: 'search',
  props: ['bases'],
  data() {
    return {
      id: '',
      orderId: '',
      tel: '',
      pan: '',
      lastName: '',
      base: [],
      isPanShow: true,
      isInvalid: false,
      isShake: false,
      selectForm: null,
      panSelector: null
    };
  },
  components: {
    TheMask
  },
  methods: {
    ...mapActions(['fetchGetResult', 'fetchGetOrderId']),
    async submitHandler() {
      let formData;
      if (this.orderId) {
        formData = {
          orderId: this.orderId,
          base: Array.from(this.base)
        };
      } else {
        formData = {
          id: this.id,
          tel: this.tel,
          pan: this.pan,
          lastName: this.lastName,
          base: Array.from(this.base)
        };
      }

      if (
        (!formData.id?.length &&
          !formData.tel?.length &&
          !formData.pan?.length &&
          !formData.lastName?.length &&
          !formData.orderId?.length) ||
        (formData.orderId?.length && formData.base?.length !== 1)
      ) {
        this.isInvalid = true;
        this.isShake = true;

        setTimeout(() => {
          this.isShake = false;
        }, 700);
        return;
      }

      if (document.documentElement.clientWidth < 795) {
        this.$emit('click');
      }

      this.$store.commit('toggleLoad', true);
      this.$store.commit('toggleSubmit', true);
      this.$store.commit('clearResult');
      this.$store.commit('clearTickets');

      if (Object.keys(this.$route.query).length) {
        this.$router.replace(`/`);
      }

      if (this.orderId) {
        await this.fetchGetOrderId(formData);
      } else {
        await this.fetchGetResult(formData);
      }

      this.$store.commit('toggleSubmit', false);
      this.$store.commit('toggleLoad', false);
    },
    onPaste(e) {
      e.preventDefault();
      const rawData = e.clipboardData.getData('text/plain');
      const data = Array.from(rawData.split(' ').join(''));

      if (data.length == 16) {
        const correctPan = [...data.slice(0, 6), ...data.slice(12)];
        let res = correctPan.join('').toString();
        this.isPanShow = false;
        this.isPanShow = true;
        this.pan = res;
      } else {
        const res = data.join('');
        this.pan = res;
      }
    }
  },
  watch: {
    orderId(value) {
      if (value) {
        this.id = '';
        this.tel = '';
        this.pan = '';
        this.lastName = '';
      }
    }
  },
  created() {
    if (localStorage.getItem('funcName') === 'fetchGetResult') {
      let cashData = JSON.parse(localStorage.getItem('funcArgs'));

      this.id = cashData.id;
      this.tel = cashData.tel;
      this.pan = cashData.pan;
      this.lastName = cashData.lastName;
      this.base = Array.from(cashData.base);
    }

    if (localStorage.getItem('funcName') === 'fetchGetOrderId') {
      let cashData = JSON.parse(localStorage.getItem('funcArgs'));

      this.orderId = cashData.orderId;
      this.base = Array.from(cashData.base);
    }
  },
  updated() {
    if (this.selectForm && this.selectForm.destroy) {
      this.selectForm.destroy();
    }
    M.updateTextFields();
    this.selectForm = M.FormSelect.init(this.$refs.selectForm, {});
  },
  mounted() {
    /* global M */
    M.updateTextFields();
    this.selectForm = M.FormSelect.init(this.$refs.selectForm, {});
    this.panSelector = document.querySelector('#pan');
    this.panSelector.addEventListener('paste', this.onPaste);
  },
  beforeDestroy() {
    if (this.selectForm && this.selectForm.destroy) {
      this.selectForm.destroy();
    }
  }
};
</script>

<style lang="scss" scoped>
.search-wrapper {
  padding: 0 10px;
}
.btn {
  width: 100%;
  &.btn_submit {
    background-color: #1e9277;
  }
}

.btn_submit.invalid {
  background-color: #a7251b;
}
span.invalid {
  color: #a7251b;
  font-size: 10px;
}
</style>
