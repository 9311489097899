<template>
  <div class="app-sidenav sidenav" :class="{ open: value }">
    <div class="profile-section" v-if="isProfile">
      <div class="logo">
        <p>ЛИЧНЫЙ КАБИНЕТ</p>
        <a @click="$emit('click')">
          <i class="material-icons theme-color-text">arrow_forward</i>
        </a>
      </div>
      <div class="collection">
        <router-link
          tag="a"
          active-class="active"
          class="collection-item"
          to="/profile"
          @click="$emit('click')"
          >Пользователи
        </router-link>
        <router-link
          tag="a"
          active-class="active"
          class="collection-item"
          to="/role"
          @click="$emit('click')"
          >Роли
        </router-link>
        <router-link
          tag="a"
          active-class="active"
          class="collection-item"
          to="/settings"
          @click="$emit('click')"
          >Настройки
        </router-link>
      </div>
    </div>
    <div class="search-section" v-else>
      <div class="logo">
        <p @click="pushStartMenu">ПОИСК</p>
        <a @click="$emit('click')">
          <i class="material-icons theme-color-text">arrow_forward</i>
        </a>
      </div>
      <SearchBase @click="$emit('click')" :bases="bases" />
      <SearchTickets @click="$emit('click')" :operators="operators" />
    </div>
  </div>
</template>

<script>
import SearchBase from '../app/SearchBar/SearchBase';
import SearchTickets from '../app/SearchBar/SearchTickets';

export default {
  components: {
    SearchBase,
    SearchTickets
  },
  props: ['value', 'isProfile', 'bases', 'operators'],
  methods: {
    pushStartMenu() {
      if (Object.keys(this.$route.query).length) {
        this.$router.replace('/');
      }
      this.$store.commit('clearResult');
      this.$store.commit('clearTickets');
      localStorage.removeItem('funcName');
      localStorage.removeItem('funcArgs');
      location.reload();
    }
  }
};
</script>

<style lang="scss" scoped>
.collection {
  margin: 0;
  .collection-item {
    color: black;
    font-size: 18px;
    text-align: center;
    height: 55px;
    line-height: 32px;
    &.active {
      background-color: #1e9277;
      color: white;
    }
  }
}
.logo {
  p {
    cursor: pointer;
    color: black;
  }
  a {
    position: absolute;
    right: 25px;
    top: 17px;
    cursor: pointer;
  }
}
@media (min-width: 795px) {
  .logo {
    a {
      display: none;
    }
  }
}
</style>
