<template>
  <div id="modal8" class="modal" ref="modal">
    <div class="modal-content">
      <h5>История подписок:</h5>
      <div v-if="subs.length" class="table_wrapper">
        <table class="highlight">
          <thead>
            <tr>
              <th>ID</th>
              <th>Начало пользования</th>
              <th>Следующая транзакция</th>
              <th>Статус</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="sub of subs" :key="sub.id">
              <td>{{ sub.Id }}</td>
              <td>{{ sub.StartDateIso }}</td>
              <td>{{ sub.NextTransactionDateIso }}</td>
              <td>{{ sub.Status }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p v-else>Подписок в CLOUD не найдено...</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'subscribe-modal',
  props: {
    subs: Array
  },
  data() {
    return {
      modal: null
    };
  },
  mounted() {
    /* global M */
    this.modal = M.Modal.init(this.$refs.modal, {
      onCloseEnd: () => {
        this.$emit('destroy-subs-modal');
      }
    });
    this.modal.open();
  },
  beforeDestroy() {
    if (this.modal && this.modal.destroy) {
      this.modal.destroy();
    }
  }
};
</script>
