import Vue from 'vue';
import Vuelidate from 'vuelidate';
import App from './App.vue';
import router from './router';
import store from './store';
import Loader from './components/app/Loader';

import Paginate from 'vuejs-paginate';
import 'materialize-css/dist/js/materialize.min.js';
import './assets/scss/index.scss';

Vue.config.productionTip = false;

Vue.use(Vuelidate);
Vue.component('paginate-core', Paginate);
Vue.component('Loader', Loader);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
