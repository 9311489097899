import store from '../store';
import router from '../router';

const HTTP_WAY = '/api/v2/cabinet';
const HEADERS = {
  'Content-Type': 'application/json',
  Accept: 'application/json'
};

const Method = {
  GET: `GET`,
  POST: `POST`,
  PUT: `PUT`,
  DELETE: `DELETE`
};

const checkStatus = response => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    store.commit('toggleLoad', false);
    store.commit('toggleSubmit', false);
    /* global M */
    if (response.status) {
      M.toast({
        html: `[Ошибка]: ${response.status}: ${response.statusText}`,
        classes: 'red darken-3',
        displayLength: 10000
      });
    } else {
      M.toast({
        html: `[Ошибка]: Неопознаная ошибка сервера, обратитесь к администратору`,
        classes: 'red darken-3',
        displayLength: 10000
      });
    }
  }
};

const checkSuccess = response => {
  if (response.result.success) {
    return response;
  } else {
    store.commit('toggleLoad', false);
    store.commit('toggleSubmit', false);

    if (
      !response.result.success &&
      response.result.message === 'Invalid token'
    ) {
      localStorage.clear();
      store.commit('clearResult');
      store.commit('clearTickets');
      router.push('/login');
      M.toast({
        html: `[Ошибка]: Токен авторизации просрочен, требуется повторная авторизация.`,
        classes: 'red darken-3',
        displayLength: 10000
      });
    }

    if (
      !response.result.success &&
      response.result.message ===
        'Error authentication - User matching query does not exist'
    ) {
      M.toast({
        html: `[Ошибка]: Пользователя нет в БД.`,
        classes: 'red darken-3',
        displayLength: 10000
      });
    }

    if (
      !response.result.success &&
      response.result.message ===
        'You do not have sufficient access rights, contact the Administrator'
    ) {
      M.toast({
        html: `[Ошибка]: Отсутсвуют права доступа. Свяжитесь с администратором.`,
        classes: 'red darken-3',
        displayLength: 10000
      });
    }

    if (
      !response.result.success &&
      typeof response.result.message === 'string' &&
      response.result.message.includes('Error authentication')
    ) {
      M.toast({
        html: `[Ошибка]: Ошибка во время авторизации - ${response.result.message}.`,
        classes: 'red darken-3',
        displayLength: 10000
      });
    }

    return response;
  }
};

const API = class {
  login(loginData) {
    try {
      return fetch('/api/v1/get_token', {
        method: Method.POST,
        body: JSON.stringify({
          username: loginData.username,
          password: loginData.password
        }),
        headers: new Headers(HEADERS)
      });
    } catch (error) {
      console.log(error);
    }
  }

  getOperatorsAndBasesInfo() {
    return this._load({
      body: JSON.stringify({
        jsonrpc: '2.0',
        method: 'cabinet.getinfo',
        params: {},
        id: new Date().getTime()
      })
    });
  }

  getQuestionary(questionaryData) {
    return this._load({
      signal: true,
      body: JSON.stringify({
        jsonrpc: '2.0',
        method: 'cabinet.search',
        params: {
          pan: questionaryData.pan,
          phonesearch: questionaryData.tel,
          id: questionaryData.id,
          lastname: questionaryData.lastName,
          target_base: questionaryData.base
        },
        id: new Date().getTime()
      })
    });
  }

  getOrderId(orderIdData) {
    return this._load({
      signal: true,
      body: JSON.stringify({
        jsonrpc: '2.0',
        method: 'cabinet.search',
        params: {
          order_id: orderIdData.orderId,
          target_base: orderIdData.base
        },
        id: new Date().getTime()
      })
    });
  }

  getTickets(tikcetsData) {
    return this._load({
      signal: true,
      body: JSON.stringify({
        jsonrpc: '2.0',
        method: 'cabinet.searchticket',
        params: {
          id: tikcetsData.id,
          target: tikcetsData.target,
          questionnaire_id: tikcetsData.questionnaire_id,
          create_ticket: tikcetsData.create_ticket,
          operator_id: tikcetsData.operator_id,
          status: tikcetsData.status,
          only_status: tikcetsData.only_status
        },
        id: new Date().getTime()
      })
    });
  }

  getTicketsInBase(ticketData) {
    return this._load({
      signal: true,
      body: JSON.stringify({
        jsonrpc: '2.0',
        method: 'cabinet.searchticket',
        params: {
          only_target: ticketData.target,
          questionnaire_id: ticketData.id
        },
        id: new Date().getTime()
      })
    });
  }

  createTicket(ticketData) {
    return this._load({
      body: JSON.stringify({
        jsonrpc: '2.0',
        method: 'cabinet.createticket',
        params: {
          questionnaire_id: ticketData.id,
          target: ticketData.target,
          status: ticketData.status,
          comment: ticketData.comment,
          file: ticketData.file
        },
        id: new Date().getTime()
      })
    });
  }

  updateTicket(ticketData) {
    const obj = {
      ticket_id: ticketData.ticket_id,
      comment: ticketData.comment
    };

    if (ticketData.status !== undefined) {
      obj.status = ticketData.status;
    }

    return this._load({
      body: JSON.stringify({
        jsonrpc: '2.0',
        method: 'cabinet.updateticket',
        params: obj,
        id: new Date().getTime()
      })
    });
  }

  clearUserInfo(userData) {
    return this._load({
      body: JSON.stringify({
        jsonrpc: '2.0',
        method: 'cabinet.clearuser',
        params: {
          questionnaire_id: userData.id,
          target_base: userData.target
        },
        id: new Date().getTime()
      })
    });
  }

  unsubscribeUser(userData) {
    return this._load({
      body: JSON.stringify({
        jsonrpc: '2.0',
        method: 'cabinet.unsubscribe',
        params: {
          phonesearch: userData.phonesearch,
          acquiring: userData.acquiring,
          target_base: userData.target
        },
        id: new Date().getTime()
      })
    });
  }

  getCenterSmsHistory(userData) {
    return this._load({
      body: JSON.stringify({
        jsonrpc: '2.0',
        method: 'cabinet.getsms',
        params: {
          start: userData.date,
          phone: userData.phone
        },
        id: new Date().getTime()
      })
    });
  }

  getSmsHistory(userData) {
    return this._load({
      body: JSON.stringify({
        jsonrpc: '2.0',
        method: 'cabinet.gettriggersms',
        params: {
          target: userData.target,
          questionnaire_id: userData.id,
          phone: userData.phonesearch
        },
        id: new Date().getTime()
      })
    });
  }

  cancelSms(userData) {
    return this._load({
      body: JSON.stringify({
        jsonrpc: '2.0',
        method: 'cabinet.nosms',
        params: {
          phone: userData.phone
        },
        id: new Date().getTime()
      })
    });
  }

  getSubscribeData(base, id) {
    return this._load({
      body: JSON.stringify({
        jsonrpc: '2.0',
        method: 'cabinet.subscriptioncloud',
        params: {
          target_base: base,
          questionnaire_id: id
        },
        id: new Date().getTime()
      })
    });
  }

  getRecurrents(base, id) {
    return this._load({
      body: JSON.stringify({
        jsonrpc: '2.0',
        method: 'cabinet.getrecurrents',
        params: {
          target_base: base,
          subscription_id: id
        },
        id: new Date().getTime()
      })
    });
  }

  changeRefundStatus(paymentData) {
    return this._load({
      body: JSON.stringify({
        jsonrpc: '2.0',
        method: 'cabinet.setstatusrefund',
        params: {
          target_base: paymentData.base,
          payment_id: paymentData.id,
          refund_status: paymentData.status
        },
        id: new Date().getTime()
      })
    });
  }

  getRefundCloud(paymentData) {
    return this._load({
      body: JSON.stringify({
        jsonrpc: '2.0',
        method: 'cabinet.refundcloud',
        params: {
          target_base: paymentData.target,
          acquiring: paymentData.acquiring,
          payment_requrrent: paymentData.paymentrequrrent
        },
        id: new Date().getTime()
      })
    });
  }

  getRefundTinkoff(paymentData) {
    return this._load({
      body: JSON.stringify({
        jsonrpc: '2.0',
        method: 'cabinet.refundtinkoff',
        params: {
          target_base: paymentData.target,
          acquiring: paymentData.acquiring,
          payment_requrrent: paymentData.paymentrequrrent,
          questionnaire_id: paymentData.questionnaire_id
        },
        id: new Date().getTime()
      })
    });
  }

  getRefund(paymentData) {
    return this._load({
      body: JSON.stringify({
        jsonrpc: '2.0',
        method: 'cabinet.refund',
        params: {
          target_base: paymentData.target,
          acquiring: paymentData.acquiring,
          payment_requrrent: paymentData.paymentrequrrent,
          questionnaire_id: paymentData.questionnaire_id
        },
        id: new Date().getTime()
      })
    });
  }

  async getAcquiring({ target_base, questionnaire_id, acquiring }) {
    try {
      const response = await fetch(
        `/api/get_answer_acquiring?target_base=${target_base}&questionnaire_id=${questionnaire_id}&acquiring=${acquiring}`,
        {
          method: Method.GET,
          headers: {
            'Content-Type': 'application/pdf',
            Authorization: localStorage.getItem('auth')
          }
        }
      );

      const checkStatusResponse = checkStatus(response);

      const filename = checkStatusResponse.headers
        .get('content-disposition')
        .replace(/attachment; filename=/, '');

      const reader = checkStatusResponse.body.getReader();
      let chunks = [];
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { done, value } = await reader.read();

        if (done) {
          break;
        }
        chunks.push(value);
      }
      let blob = new Blob([...chunks], { type: 'application/pdf' });
      store.commit('toggleLoad', false);

      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', downloadUrl);
      link.setAttribute('download', filename);
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(link.href);
      document.body.removeChild(link);

      // window.open(URL.createObjectURL(fileOfBlob));
    } catch (error) {
      console.log(error);
    }
  }

  async getTicketFile(file) {
    try {
      const response = await fetch(`/api/get_file?file_name=${file}`, {
        method: Method.GET,
        headers: {
          'Content-Type': 'image/png',
          Authorization: localStorage.getItem('auth')
        }
      });
      const checkStatusResponse = checkStatus(response);

      const reader = checkStatusResponse.body.getReader();
      let chunks = [];
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { done, value } = await reader.read();

        if (done) {
          break;
        }
        chunks.push(value);
      }

      let blob = new Blob([...chunks], { type: 'image/png' });
      store.commit('toggleLoad', false);

      const url = URL.createObjectURL(blob);
      return url;
    } catch (error) {
      console.log(error);
    }
  }

  async generatePackage({ target_base, acquiring, questionnaire_id }) {
    try {
      const response = await fetch(
        `/api/get_package?target_base=${target_base}&questionnaire_id=${questionnaire_id}&acquiring=${acquiring}`,
        {
          method: Method.GET,
          headers: {
            'Content-Type': 'application/zip',
            Authorization: localStorage.getItem('auth')
          }
        }
      );

      const checkStatusResponse = checkStatus(response);

      const filename = checkStatusResponse.headers
        .get('content-disposition')
        .replace(/attachment; filename=/, '');

      const reader = checkStatusResponse.body.getReader();
      let chunks = [];
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { done, value } = await reader.read();

        if (done) {
          break;
        }
        chunks.push(value);
      }
      let blob = new Blob([...chunks], { type: 'application/zip' });
      store.commit('toggleLoad', false);

      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', downloadUrl);
      link.setAttribute('download', filename);
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(link.href);
      document.body.removeChild(link);

      // window.open(URL.createObjectURL(blob));
    } catch (error) {
      console.log(error);
    }
  }

  getReceipt({ target_base, questionnaire_id, payment_requrrent }) {
    return this._load({
      body: JSON.stringify({
        jsonrpc: '2.0',
        method: 'cabinet.getreceipt',
        params: {
          target_base,
          questionnaire_id,
          payment_requrrent
        },
        id: new Date().getTime()
      })
    });
  }

  getStatusAuth({ target_base, questionnaire_id }) {
    return this._load({
      body: JSON.stringify({
        jsonrpc: '2.0',
        method: 'cabinet.getstatusauth',
        params: {
          target_base,
          questionnaire_id
        },
        id: new Date().getTime()
      })
    });
  }

  getSettings() {
    return this._load({
      body: JSON.stringify({
        jsonrpc: '2.0',
        method: 'cabinet.getsettings',
        params: {},
        id: new Date().getTime()
      })
    });
  }

  setSettings(settingsData) {
    return this._load({
      body: JSON.stringify({
        jsonrpc: '2.0',
        method: 'cabinet.setsettings',
        params: {
          settings: settingsData
        },
        id: new Date().getTime()
      })
    });
  }

  _load({
    method = Method.POST,
    body = null,
    headers = new Headers(HEADERS),
    signal = false
  }) {
    headers.append('Authorization', localStorage.getItem('auth'));

    let fetchSetting = {
      method,
      body,
      headers
    };

    // Очищает триггер сброса запросов
    store.commit('clear');
    store.commit('setAbort', false);

    if (signal) {
      store.commit('setAbort', true);
      fetchSetting.signal = store.state.controller.controller.signal;
    }

    try {
      return fetch(HTTP_WAY, fetchSetting)
        .then(checkStatus)
        .then(resp => resp.json())
        .then(checkSuccess)
        .catch(error => {
          store.commit('toggleLoad', false);
          store.commit('toggleSubmit', false);
          throw new Error(error);
        });
    } catch (error) {
      store.commit('toggleLoad', false);
      store.commit('toggleSubmit', false);
      throw new Error(error);
    }
  }
};

export default API;
