<template>
  <button
    class="waves-effect waves-light btn btn_up"
    :class="{ jump: isJump }"
    @click="$emit('throw-page-up')"
  >
    <i class="material-icons left">arrow_upward</i>
  </button>
</template>

<script>
export default {
  name: 'button-up',
  data() {
    return {
      isJump: false,
      timeInterval: null
    };
  },
  created() {
    this.timeInterval = setInterval(() => {
      this.isJump = !this.isJump;
    }, 5000);
  },
  beforeDestroy() {
    clearInterval(this.timeInterval);
  }
};
</script>

<style lang="scss" scoped>
.btn_up {
  width: 45px;
  height: 45px;
  position: fixed;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00b18891;
  border: 3px solid white;
  bottom: 25px;
  right: 65px;
  z-index: 500;
  border-radius: 50%;
  &:focus {
    background-color: #1e9277;
  }
  &:hover {
    background-color: #1e9277;
    box-shadow: 0 0 30px 5px #1e9277;
  }
  @media (max-width: 850px) {
    right: 45px;
  }
  i {
    margin: 0;
    font-size: 36px;
  }
}
</style>
