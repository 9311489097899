<template>
  <table class="highlight">
    <thead>
      <tr>
        <th class="click" :class="isDateSorted && 'sort'" @click="sortByDate">
          Дата
        </th>
        <th>Статус</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="history of histories" :key="history.id">
        <td>
          {{ history.change_date ? transformDate(history.change_date) : '' }}
        </td>
        <td>{{ history.state }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { deepClone } from '../../../utils/deepClone';
import { getFullFormatDate } from '../../../utils/transformDate';
export default {
  name: 'ticket-history',
  props: {
    history: Object
  },
  data() {
    return {
      items: '',
      filterItems: '',
      isDateSorted: false
    };
  },
  computed: {
    histories() {
      if (
        this.filterItems.subscription_history === undefined ||
        !this.filterItems.subscription_history.length
      ) {
        return {
          filterItems: {
            change_date: '',
            state: '',
            id: 1
          }
        };
      }
      return this.filterItems.subscription_history;
    }
  },
  methods: {
    sortByDate() {
      if (!this.isDateSorted) {
        this.isDateSorted = true;
        this.filterItems.subscription_history.sort((a, b) => {
          const firstTime = new Date(a.change_date).getTime();
          const secondTime = new Date(b.change_date).getTime();
          return secondTime - firstTime;
        });
      } else {
        this.isDateSorted = false;
        this.filterItems.subscription_history.sort((a, b) => {
          const firstTime = new Date(a.change_date).getTime();
          const secondTime = new Date(b.change_date).getTime();
          return firstTime - secondTime;
        });
      }
    },
    transformDate(date) {
      return getFullFormatDate(date);
    }
  },
  created() {
    this.items = this.history;
    this.filterItems = deepClone(this.items);
  }
};
</script>

<style lang="scss" scoped>
th.click {
  cursor: pointer;
  &.sort {
    border-bottom: 2px solid green;
  }
}
</style>
