<template>
  <div>
    <h1>Результаты поиска</h1>
    <SearchFilter
      @filter-request="filterBases"
      @clear-filter="clearFilterBase"
      @sort-by-date="sortByDate"
      @close-collapse="closeCollapse"
    />
    <div class="search_grid">
      <SearchResultTable
        @toggle-show="toggleShow"
        v-for="(base, baseName) in filtredBases"
        :key="baseName"
        :title="baseName"
        :users="base.users"
        :isCloseCollapse="isCloseCollapse"
      />
    </div>
  </div>
</template>

<script>
import { deepClone } from '../utils/deepClone.js';
import SearchFilter from '../components/app/SearchBar/SearchFilter';
import SearchResultTable from '../components/app/SearchResult/SearchResultTable';
export default {
  name: 'search-result',
  components: {
    SearchResultTable,
    SearchFilter
  },
  data() {
    return {
      isCloseCollapse: false,
      bases: null,
      filtredBases: null
    };
  },
  methods: {
    filterBases(filterValue) {
      for (const key in this.filtredBases) {
        const filtredResult = this.filtredBases[key].users.filter(userData => {
          if (userData.pan === null) {
            userData.pan = '';
          }
          if (/\d/.test(filterValue)) {
            filterValue = filterValue.replace(/\D+/g, '');
          }

          return (
            userData.contact.phonesearch.toString().includes(filterValue) ||
            userData.pan.toString().includes(filterValue) ||
            userData.contact.lastname
              .toUpperCase()
              .includes(filterValue.toUpperCase()) ||
            userData.contact.firstname
              .toUpperCase()
              .includes(filterValue.toUpperCase())
          );
        });
        this.filtredBases[key].users = filtredResult;
      }
    },
    sortByDate() {
      for (const key in this.filtredBases) {
        this.filtredBases[key].users.sort((a, b) => {
          const isA = !!a.payment_transaction.length;
          const isB = !!b.payment_transaction.length;

          let verificateA = isA
            ? a.payment_transaction.find(transaction => transaction.ispay == 1)
            : undefined;
          let verificateB = isB
            ? b.payment_transaction.find(transaction => transaction.ispay == 1)
            : undefined;

          if (verificateA === undefined) {
            verificateA = { createdat: null };
          }
          if (verificateB === undefined) {
            verificateB = { createdat: null };
          }

          return (
            new Date(verificateB.createdat).valueOf() -
            new Date(verificateA.createdat).valueOf()
          );
        });
      }
    },
    clearFilterBase() {
      this.filtredBases = deepClone(this.bases);
    },
    toggleShow(id, title) {
      this.filtredBases[title].users.forEach(it => {
        it.isShow = it.id === id;
      });
    },
    closeCollapse() {
      this.isCloseCollapse = true;
      this.$nextTick(() => {
        this.isCloseCollapse = false;
      });
    }
  },
  created() {
    this.bases = this.$store.state.search.result;
    this.filtredBases = deepClone(this.bases);
  }
};
</script>

<style lang="scss" scoped>
.search_grid {
  width: 100%;
}
</style>
