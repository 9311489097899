<template>
  <TicketZeroForm
    :ticket="ticket"
    :id="id"
    :target="target"
    @refresh-tickets="getTickets()"
  />
</template>

<script>
import API from '../../../utils/API';
import TicketZeroForm from './ TicketZeroForm';
import { getFullFormatDate } from '../../../utils/transformDate';
import { mapTicketStatus } from '../../../utils/mapTicketStatus';
export default {
  name: 'ticket-update-table',
  components: {
    TicketZeroForm
  },
  props: {
    target: String,
    id: Number,
    ticketId: Number
  },
  data() {
    return {
      isUpdateForm: false,
      isCreateForm: false,
      isForm: false,
      ticket: null,
      tickets: [],
      role: null,
      statuses: mapTicketStatus,
      isActiveSort: false
    };
  },
  methods: {
    async getTickets() {
      this.$store.commit('toggleLoad', true);

      const kabinte_api = new API();
      const response = await kabinte_api.getTickets({
        id: this.ticketId
      });

      this.ticket = response.result.message.tickets[0];
      this.role = response.result.role;
      this.$emit('set-role', this.role);

      if (!this.tickets.length) {
        this.tickets = {
          ticket: {
            id: '',
            auth_user: { username: '' },
            operator: '',
            create_ticket: '',
            status: ''
          }
        };
      } else {
        const activeTicket = this.tickets.find(it => it.status !== 1);
        this.$emit(
          'set-active-ticket',
          activeTicket,
          activeTicket !== undefined
        );
        const chargeTicket = this.tickets.find(it => it.status === 5);
        this.$emit(
          'set-charge-ticket',
          chargeTicket,
          chargeTicket !== undefined
        );
      }

      this.$store.commit('toggleLoad', false);
    },
    transformDate(date) {
      return getFullFormatDate(date);
    }
  },
  created() {
    this.getTickets();
  }
};
</script>

<style lang="scss" scoped>
.ticket_update_grid {
  display: flex;
  flex-direction: column;
  position: relative;

  button {
    &.checkbox_btn {
      position: absolute;
      top: -32px;
      left: 110px;
      height: 24px;
      width: 24px;
      line-height: 24px;
      background: #bdbdbd;
      color: #3c4b64;
      font-size: 12px;
    }
    &.date_sort {
      background: #4caf50;
      color: #fff;
    }
    i {
      font-size: 20px;
      line-height: 24px;
    }
  }

  .button-create-ticket {
    margin: 15px 0 10px;
    background-color: #1e9277;
  }
}
</style>
