<template>
  <div>
    <table class="highlight table_1">
      <thead>
        <tr>
          <th>ID</th>
          <th>Фамилия</th>
          <th>Имя</th>
          <th>Отчество</th>
          <th>Телефон</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ ticket.id }}</td>
          <td>{{ ticket.contact.lastname }}</td>
          <td>{{ ticket.contact.firstname }}</td>
          <td>{{ ticket.contact.patronymic }}</td>
          <td>{{ ticket.contact.phone }}</td>
        </tr>
      </tbody>
    </table>

    <table class="highlight">
      <thead>
        <tr>
          <th>База</th>
          <th>Дата создания</th>
          <th>PAN</th>
          <th>Статус подписки</th>
          <th>Эквайринг</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ target }}</td>
          <td>{{ transformDate(ticket.createdat) }}</td>
          <td>{{ ticket.pan }}</td>
          <td>{{ ticket.subscription_id.status }}</td>
          <td>{{ ticket.acquiring }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { getFullFormatDate } from '../../../utils/transformDate';

export default {
  name: 'ticket-table',
  props: {
    ticket: Object,
    target: String
  },
  methods: {
    transformDate(date) {
      return getFullFormatDate(date);
    }
  }
};
</script>

<style lang="scss" scoped>
.table_1 {
  margin-bottom: 10px;
}
</style>
