<template>
  <div v-if="this.ticketInfo.id !== 0">
    <div class="header-wrapper">
      <h5>Анкета - {{ this.ticketInfo.id }}</h5>
      <div class="auth-wrapper">
        <LoaderMini v-if="!isLoaderMini" />
        <span v-else>{{ authStatus }}</span>
      </div>
    </div>

    <TicketTable :ticket="this.ticketInfo" :target="target" />
    <TicketHeaders :headers="buttonsPanel" @click="toggleButtons" />
    <TicketButtons
      v-show="buttonsPanel.isShow"
      @refresh-data="getCurrentInfo"
      :ticket="this.ticketInfo"
      :target="target"
      :activeTicket="activeTicket"
      :isActiveTicket="isActiveTicket"
      :userStatus="userStatus"
    />
    <div class="grid_ticket">
      <div class="grid_ticket-child">
        <TicketHeaders :headers="paymentPanel" @click="togglePayment" />
        <TicketPayment
          v-show="paymentPanel.isShow"
          :payments="this.ticketInfo.subscription_id"
          :acquiring="this.ticketInfo.acquiring"
          :userStatus="userStatus"
          :id="this.ticketInfo.id"
          :target="target"
          :isChargeTicket="isChargeTicket"
          :chargeTicket="chargeTicket"
        />
      </div>
      <div class="grid_ticket-child">
        <TicketHeaders :headers="historyPanel" @click="toggleHistory" />
        <TicketHistory
          v-show="historyPanel.isShow"
          :history="this.ticketInfo.subscription_id"
        />
      </div>
    </div>

    <div class="">
      <TicketHeaders :headers="updatePanel" @click="toggleUpdate" />
      <TicketsTable
        v-show="updatePanel.isShow"
        :id="this.ticketInfo.id"
        :target="target"
        @set-active-ticket="setActiveTicket"
        @set-charge-ticket="setChargeTicket"
        @set-role="setRole"
      />
    </div>
  </div>
</template>

<script>
import API from '../utils/API';
import LoaderMini from '../components/app/LoaderMini';
import TicketHeaders from '../components/app/Ticket/TicketHeaders';
import TicketTable from '../components/app/Ticket/TicketTable';
import TicketButtons from '../components/app/Ticket/TicketButtons';
import TicketPayment from '../components/app/Ticket/TicketPayment';
import TicketHistory from '../components/app/Ticket/TicketHistory';
import TicketsTable from '../components/app/Ticket/TicketsTable';
export default {
  name: 'ticket',
  components: {
    LoaderMini,
    TicketHeaders,
    TicketButtons,
    TicketTable,
    TicketPayment,
    TicketHistory,
    TicketsTable
  },
  props: {
    user: Object,
    target: String
  },
  data() {
    return {
      authStatus: '',
      isLoaderMini: false,
      ticketInfo: this.user,
      isActiveTicket: false,
      activeTicket: null,
      isChargeTicket: false,
      chargeTicket: null,
      userStatus: 'admin',
      buttonsPanel: {
        title: 'Управление анкетой',
        isShow: true
      },
      paymentPanel: {
        title: 'Платежи',
        isShow: true
      },
      historyPanel: {
        title: 'История подписок',
        isShow: true
      },
      updatePanel: {
        title: 'Тикеты',
        isShow: true
      }
    };
  },
  methods: {
    toggleButtons() {
      this.buttonsPanel.isShow = !this.buttonsPanel.isShow;
    },
    togglePayment() {
      this.paymentPanel.isShow = !this.paymentPanel.isShow;
    },
    toggleHistory() {
      this.historyPanel.isShow = !this.historyPanel.isShow;
    },
    toggleUpdate() {
      this.updatePanel.isShow = !this.updatePanel.isShow;
    },
    setActiveTicket(ticket, bool) {
      this.isActiveTicket = bool;
      if (ticket !== undefined) {
        this.activeTicket = ticket.id;
      }
    },
    setChargeTicket(ticket, bool) {
      this.isChargeTicket = bool;
      if (ticket !== undefined) {
        this.chargeTicket = ticket.id;
      }
    },
    setRole(role) {
      this.userStatus = role;
    },

    async getCurrentInfo() {
      const kabinetAPI = new API();
      const response = await kabinetAPI.getQuestionary({
        id: this.user.id,
        base: [this.target]
      });
      this.ticketInfo = response.result.message[this.target].users[0];
    },
    async getStatusAuth() {
      const kabinetAPI = new API();
      const response = await kabinetAPI.getStatusAuth({
        questionnaire_id: this.user.id,
        target_base: this.target
      });
      if (response.result.success) {
        this.authStatus = response.result.message;
      }
      this.isLoaderMini = true;
    }
  },
  created() {
    this.getCurrentInfo();
    this.getStatusAuth();
  }
};
</script>

<style lang="scss" scoped>
.grid_ticket {
  display: flex;
  .grid_ticket-child {
    &:nth-child(1) {
      width: 65%;
      margin-right: 5px;
    }
    &:nth-child(2) {
      width: 35%;
    }
  }
}

h5 {
  margin-right: 20px;
}
.header-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}

.auth-wrapper {
  max-width: 100%;
}
</style>
