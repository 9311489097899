<template>
  <div id="app" class="grey lighten-3">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import AuthLayout from '@/layouts/AuthLayout';
import MainLayout from '@/layouts/MainLayout';
import ProfileLayout from '@/layouts/ProfileLayout';

export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || 'auth') + '-layout';
    }
  },
  components: {
    AuthLayout,
    MainLayout,
    ProfileLayout
  }
};
</script>

<style lang="scss"></style>
