<template>
  <div class="search-wrapper">
    <h5>Поиск тикета:</h5>

    <form @submit.prevent="submitHandler">
      <div class="input-field">
        <select ref="operator" v-model="operator">
          <option value="" selected>Оператор</option>
          <optgroup
            v-for="operator of Object.keys(operators)"
            :label="operator"
            :key="operator"
          >
            <option
              v-for="name of operators[operator]"
              :value="name"
              :key="name"
              >{{ name }}</option
            >
          </optgroup>
        </select>
        <label>Выберите оператора</label>
      </div>
      <div class="status-group">
        <div class="input-field">
          <select ref="status" v-model="status">
            <option value="" selected>Статус</option>
            <option v-for="(status, key) of statuses" :key="key" :value="key">{{
              status
            }}</option>
          </select>
          <label>Выберите статус</label>
        </div>

        <label>
          <input type="checkbox" class="filled-in" v-model="isOnlyStatus" />
          <span class="checkbox-wrapper"></span>
        </label>
      </div>
      <div class="input-field">
        <the-mask
          id="questionId"
          name="questionId"
          type="text"
          autocomplete="off"
          placeholder="000000"
          v-model.trim="questionId"
          mask="############"
        />
        <label for="questionId">ID анкеты</label>
      </div>
      <div class="input-field">
        <the-mask
          id="ticketId"
          name="ticketId"
          type="text"
          autocomplete="off"
          placeholder="000000"
          v-model.trim="ticketId"
          mask="############"
        />
        <label for="ticketId">ID тикета</label>
      </div>

      <div class="input-field picker">
        <input
          id="date"
          name="date"
          type="date"
          autocomplete="off"
          placeholder="дд.мм.гггг"
          v-model.trim="dateStart"
          :max="maxDateStart"
        />
        <label for="date">Дата начала</label>
        <button type="button" class="btn clear-btn" @click="dateStart = ''">
          <i class="material-icons">close</i>
        </button>
      </div>
      <div class="input-field picker">
        <input
          id="date"
          name="date"
          type="date"
          autocomplete="off"
          placeholder="дд.мм.гггг"
          v-model.trim="dateFinish"
          :min="minDateFinish"
        />
        <label for="date">Дата конца</label>
        <button type="button" class="btn clear-btn" @click="dateFinish = ''">
          <i class="material-icons">close</i>
        </button>
      </div>
      <button
        class="btn waves-effect waves-light btn_submit"
        type="submit"
        :disabled="$store.state.submit"
      >
        Поиск тикетa
        <i class="material-icons right">search</i>
      </button>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { TheMask } from 'vue-the-mask';
import { mapTicketStatus } from '../../../utils/mapTicketStatus';
import {
  getYYYY_MM_DDFormatDate,
  getDMYFormatDate
} from '../../../utils/transformDate';
export default {
  name: 'tickets-request',
  components: {
    TheMask
  },
  props: ['operators'],
  data() {
    return {
      ticketId: '',
      questionId: '',
      operator: '',
      status: '',
      dateStart: '',
      maxDateStart: '',
      dateFinish: '',
      minDateFinish: '',
      isOnlyStatus: false,
      statuses: mapTicketStatus,
      operatorSelect: null,
      statusSelect: null
    };
  },
  methods: {
    ...mapActions(['fetchGetTickets']),
    async submitHandler() {
      const newDate = new Date();
      const formatDate = getDMYFormatDate(newDate);
      const timeRange =
        this.dateStart && this.dateFinish
          ? [
              getDMYFormatDate(this.dateStart),
              getDMYFormatDate(this.dateFinish)
            ]
          : this.dateStart
          ? [getDMYFormatDate(this.dateStart), formatDate]
          : this.dateFinish
          ? ['01.01.2000', getDMYFormatDate(this.dateFinish)]
          : [];

      const formData = {
        id: this.ticketId,
        questionnaire_id: this.questionId,
        create_ticket: timeRange,
        operator_id: this.operator ? this.getOperatorId(this.operator) : '',
        status: '',
        only_status: ''
      };

      this.isOnlyStatus
        ? (formData.only_status = this.status)
        : (formData.status = this.status);

      if (document.documentElement.clientWidth < 795) {
        this.$emit('click');
      }

      this.$store.commit('toggleLoad', true);
      this.$store.commit('toggleSubmit', true);
      this.$store.commit('clearResult');
      this.$store.commit('clearTickets');

      if (Object.keys(this.$route.query).length) {
        this.$router.replace(`/`);
      }

      await this.fetchGetTickets(formData);

      this.$store.commit('toggleSubmit', false);
      this.$store.commit('toggleLoad', false);
    },
    getOperatorId(operator) {
      const reg = /(\d+)#/;
      return operator.match(reg)[1];
    }
  },
  watch: {
    dateFinish(value) {
      this.maxDateStart = value;
    },
    dateStart(value) {
      this.minDateFinish = value;
    }
  },
  created() {
    if (localStorage.getItem('funcName') === 'fetchGetTickets') {
      let cashData = JSON.parse(localStorage.getItem('funcArgs'));
      this.ticketId = cashData.id;
      this.questionId = cashData.questionnaire_id;
      this.operator = cashData.operator_id;
      this.status = cashData.status;
      if (cashData.create_ticket.length) {
        this.dateStart = getYYYY_MM_DDFormatDate(cashData.create_ticket[0]);
        this.dateFinish = getYYYY_MM_DDFormatDate(cashData.create_ticket[1]);
      }
    }
  },
  mounted() {
    /*global M*/
    this.operatorSelect = M.FormSelect.init(this.$refs.operator, {});
    this.statusSelect = M.FormSelect.init(this.$refs.status, {});

    M.updateTextFields();
  },
  updated() {
    if (this.operatorSelect && this.operatorSelect.destroy) {
      this.operatorSelect.destroy();
    }
    this.operatorSelect = M.FormSelect.init(this.$refs.operator, {});
  },
  beforeDestroy() {
    if (this.operatorSelect && this.operatorSelect.destroy) {
      this.operatorSelect.destroy();
    }
    if (this.statusSelect && this.statusSelect.destroy) {
      this.statusSelect.destroy();
    }
  }
};
</script>

<style lang="scss" scoped>
.search-wrapper {
  padding: 0 10px;
}
.request-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.input-field {
  min-width: 100%;
}

button {
  width: 100%;
  margin-bottom: 20px;

  &.btn_submit {
    background-color: #1e9277;
  }
}
.search_checkbox {
  margin-bottom: 14px;
}
.picker {
  position: relative;
  button {
    display: grid;
    padding: 0;
    border: none;
    position: absolute;
    background-color: transparent;
    box-shadow: none;
    top: 3px;
    right: 30px;
    width: 25px;
    height: 25px;
    i {
      place-self: center;
      color: black;
      text-align: center;
      font-size: 18px;
      font-weight: 400;

      transition: all 0.3s ease;
    }
    &:hover {
      i {
        font-weight: 800;
        color: red;
      }
    }
  }
}

.status-group {
  display: flex;
  align-items: center;

  span {
    &.checkbox-wrapper {
      margin-left: 10px;
      padding-left: 20px;
    }
  }

  .input-field {
    min-width: auto;
    width: 100%;
    margin: 0;
  }
}
</style>
