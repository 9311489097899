const castTimeFormat = value => {
  return value < 10 ? `0${value}` : value;
};

const getYYYY_MM_DDFormatDate = date => {
  let arrDate = date.split('.');
  return `${arrDate[2]}-${arrDate[1]}-${arrDate[0]}`;
};

const getYMDFormatDate = dateUTC => {
  const rawLocalDate = new Date(dateUTC);
  const localYear = rawLocalDate.getFullYear();
  const localMonth = rawLocalDate.getMonth() + 1;
  const localDay = rawLocalDate.getDate();
  return (
    localYear +
    '.' +
    castTimeFormat(localMonth) +
    '.' +
    castTimeFormat(localDay)
  );
};

const getDMYFormatDate = dateUTC => {
  const rawLocalDate = new Date(dateUTC);
  const localYear = rawLocalDate.getFullYear();
  const localMonth = rawLocalDate.getMonth() + 1;
  const localDay = rawLocalDate.getDate();
  return (
    castTimeFormat(localDay) +
    '.' +
    castTimeFormat(localMonth) +
    '.' +
    localYear
  );
};

const getFullFormatDate = dateUTC => {
  const rawLocalDate = new Date(dateUTC);
  const localYear = rawLocalDate.getFullYear();
  const localMonth = rawLocalDate.getMonth() + 1;
  const localDay = rawLocalDate.getDate();
  const localHour = rawLocalDate.getHours();
  const localMintute = rawLocalDate.getMinutes();

  return (
    localYear +
    '.' +
    castTimeFormat(localMonth) +
    '.' +
    castTimeFormat(localDay) +
    ' ' +
    castTimeFormat(localHour) +
    ':' +
    castTimeFormat(localMintute)
  );
};

const getFullFormatWithSecDate = dateUTC => {
  const rawLocalDate = new Date(dateUTC);
  const localYear = rawLocalDate.getFullYear();
  const localMonth = rawLocalDate.getMonth() + 1;
  const localDay = rawLocalDate.getDate();
  const localHour = rawLocalDate.getHours();
  const localMintute = rawLocalDate.getMinutes();
  const localSecond = rawLocalDate.getSeconds();

  return (
    localYear +
    '.' +
    castTimeFormat(localMonth) +
    '.' +
    castTimeFormat(localDay) +
    ' ' +
    castTimeFormat(localHour) +
    ':' +
    castTimeFormat(localMintute) +
    ':' +
    castTimeFormat(localSecond)
  );
};

const getCapitalLetter = word => {
  return word[0].toUpperCase() + word.slice(1);
};

export {
  getYYYY_MM_DDFormatDate,
  getYMDFormatDate,
  getDMYFormatDate,
  getFullFormatDate,
  getCapitalLetter,
  getFullFormatWithSecDate
};
